import { FC, useState, useEffect } from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Stack,
  Input,
  FormLabel,
  Select,
  Button,
  useDisclosure,
  FormControl,
  List,
  ListItem,
} from "@chakra-ui/react";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import { User, useUserState } from "../../hookstate/userstate";
import { useRoleState } from "../../hookstate/roleState";
import { api } from "../../hookstate/apiState";
import { useLoginState } from "../../hookstate/loginState";
import config from "../../config";
import useDebounce from "../../utils/useDebounce";
import { useClinicState } from "../../hookstate/clinicState";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Must be a valid email")
    .max(255),
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("name is required"),
  username: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("username is required"),
  clinicName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("clinicName is required"),
  roleID: Yup.string().required("roleID is required"),
});

type EditUsersContainerProps = {
    id: number,
    email: string,
    name: string,
    username: string,
    clinicName: string,
    clinicID: number,
    roleID: string,
}

const EditUsersContainer: FC<EditUsersContainerProps> = (props: EditUsersContainerProps) => {
  const [inputClinicName, setInputClinicName] = useState<string>("");

  const debouncedClinic: string = useDebounce<string>(inputClinicName, 500);
  const [clinicsResult, setClinicsResult] = useState<any[]>([]);
  const [selectedClinicId, setSelectedClinicId] = useState<number>(props.clinicID);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const userState = useUserState();
  const roleState = useRoleState();
  const clinicState = useClinicState();
  const loginState = useLoginState();
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Once the user has selected an autosuggested input, clear the name input and remove the dropdowns
  const handleClearClinicValues = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    setInputClinicName("");
    setClinicsResult([]);
  };

//   useEffect(() => {
//     roleState.callGetRolesApi();
//     clinicState.callGetClinicsApi();
//   }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (debouncedClinic) {
      setIsSearching(true);

      api
        .getAutoComplete(loginState.session, inputClinicName)

        .then((autoClinicsRes) => {
          setClinicsResult(autoClinicsRes.payload);
          setIsSearching(false);
        });
    } else {
      setClinicsResult([]);
    }
  }, [debouncedClinic]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
    <Flex justifyContent="end">
      <Button
        colorScheme="teal"
        backgroundColor="#285FAA"
        onClick={onOpen}
        padding="2px 15px 2px 15px"
        height="34px"
      >
        Edit
      </Button>
    </Flex>

      <Drawer
        size="lg"
        isOpen={isOpen}
        placement="right"
        // initialFocusRef={name}
        onClose={onClose}
      >
        <Formik
          initialValues={{
            email: props.email,
            name: props.name,
            username: props.username,
            clinicName: props.clinicName,
            clinicID: props.clinicID,
            roleID: props.roleID,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, _actions) => {
            // handle edge case where a user has input the clinic name correclty, but not chosen the
            // autosuggest suggestion, meaning the selectedClinicId variable is still an empty string
            if (values.clinicName && !selectedClinicId) {
                // If the users input matches the name of an existing clinic, setSelectedClinicId to that clinics id
                const clinicID = await clinicState.Clinics.find(
                  (clinic) => clinic.name === values.clinicName
                )?.id.toString();
  
                if (clinicID) {
                    let clinicIDNum = parseInt(clinicID);
                  await setSelectedClinicId(clinicIDNum);
                }
            }

            if (!executeRecaptcha) {
              return;
            }

            const recaptchaToken = await executeRecaptcha("UserLogin");

            userState.callEditUserApi(
                props.id,
                values.name,
                values.username,
                values.email,
                selectedClinicId,
                values.roleID,
                recaptchaToken,
                () => navigate(0)
            );
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader borderBottomWidth="1px">
                    Edit User
                  </DrawerHeader>

                  <DrawerBody>
                    <Stack spacing="24px">
                      <FormControl>
                        <FormLabel htmlFor="name">Name</FormLabel>
                        <Input
                          name="name"
                          type="text"
                          onChange={handleChange}
                          value={values.name}
                          placeholder="Name"
                        />
                        {errors.name && touched.name ? (
                          <Box color={"red"} padding={"1"}>
                            {errors.name}
                          </Box>
                        ) : null}
                      </FormControl>

                      <FormControl>
                        <FormLabel htmlFor="email">Email</FormLabel>
                        <Input
                          name="email"
                          type="email"
                          onChange={handleChange}
                          value={values.email}
                          placeholder="Email Address"
                        />
                        {errors.email && touched.email ? (
                          <Box color={"red"} padding={"1"}>
                            {errors.email}
                          </Box>
                        ) : null}
                      </FormControl>

                      <FormControl>
                        <FormLabel htmlFor="username">Username</FormLabel>

                        <Input
                          name="username"
                          type="text"
                          onChange={handleChange}
                          value={values.username}
                          placeholder="Username"
                        />
                        {errors.username && touched.username ? (
                          <Box color={"red"} padding={"1"}>
                            {errors.username}
                          </Box>
                        ) : null}
                      </FormControl>

                        <FormControl>
                        <FormLabel htmlFor="clinicName">Clinic Name</FormLabel>
                        <Input
                            name="clinicName"
                            type="text"
                            onChange={(e) => {
                            if (e.target.value === "") {
                                setClinicsResult([]);
                            } else {
                                setInputClinicName(e.target.value);
                                setClinicsResult(clinicsResult);
                            }
                            handleChange(e);
                            }}
                            value={values.clinicName}
                            placeholder="Type more than 3 characters to search for a clinic"
                        />
                        {errors.clinicName && touched.clinicName ? (
                            <Box color={"red"} padding={"1"}>
                            {errors.clinicName}
                            </Box>
                        ) : null}
                        </FormControl>
                      <List>
                        {isSearching && <Box color="red">Searching ...</Box>}

                        {clinicsResult.length > 0 &&
                          inputClinicName.length > 0 &&
                          !isSearching &&
                          clinicsResult.map(({ id, name }) => {
                            return (
                              <ListItem
                                padding={"0.5rem"}
                                value={id}
                                key={id}
                                onClick={(event) => {
                                  setInputClinicName(name);
                                  handleClearClinicValues(event);
                                  values.clinicID = id;
                                  setSelectedClinicId(id);
                                }}
                                _hover={{
                                  backgroundColor: "#00ccff",
                                  color: "white",
                                  cursor: "pointer",
                                  fontWeight: 700,
                                }}
                              >
                                {name}
                              </ListItem>
                            );
                          })}
                        {clinicsResult.length === 0 &&
                          inputClinicName.length > 0 && (
                            <Box color="red">
                              No available searching results
                            </Box>
                          )}
                      </List>
                      <FormControl>
                        <FormLabel htmlFor="roleID">Role</FormLabel>
                        <Select name="roleID" onChange={handleChange} value={props.roleID}>
                          {roleState.Roles.map((role) => {
                            return (
                              <option key={role.id} value={role.id.toString(10)}>
                                {role.name}
                              </option>
                            );
                          })}
                        </Select>
                        {errors.roleID && touched.roleID ? (
                          <Box color={"red"} padding={"1"}>
                            {errors.roleID}
                          </Box>
                        ) : null}
                      </FormControl>
                    </Stack>
                  </DrawerBody>

                  <DrawerFooter borderTopWidth="1px">
                    <RouteLink to={`/users`}>
                      <Button variant="outline" mr={3} onClick={onClose}>
                        Cancel
                      </Button>
                    </RouteLink>
                    <Button
                      borderRadius={5}
                      type="submit"
                      variant="solid"
                      colorScheme="blue"
                    >
                      Submit
                    </Button>
                  </DrawerFooter>
                </DrawerContent>
              </form>
            );
          }}
        </Formik>
      </Drawer>
    </>
  );
};

type EditUsersProps = {
    user: User,
}

const EditUsers: FC<EditUsersProps> = (props: EditUsersProps) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
            <EditUsersContainer
                id={props.user.id}
                email={props.user.email}
                username={props.user.email}
                name={props.user.name}
                clinicName={props.user.clinic.name}
                clinicID={props.user.clinic.id}
                roleID={props.user.role.id.toString(10)}
                />
        </GoogleReCaptchaProvider>
    );
};
export default EditUsers;
