import { Outlet } from "react-router-dom";
import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  useColorMode,
} from "@chakra-ui/react";
import TopBar from "./TopBar";
import SideBar from "./SideBar";

const SimpleSidebar: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <TopBar
        onOpen={onOpen}
        toggleColorMode={toggleColorMode}
        colorMode={colorMode}
      />

      <Box display="flex" height="100%">
        <Box
          bg={useColorModeValue("gray.100", "gray.900")}
          display="flex"
          flex="0 0 auto"
          overflow="hidden"
        >
          <SideBar
            onClose={() => onClose}
            isOpen={isOpen}
            display={{ base: "none", md: "block" }}
          />

          <Drawer
            autoFocus={false}
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            returnFocusOnClose={false}
            onOverlayClick={onClose}
            size="xs"
          >
            <DrawerContent>
              <Box mt={50}>
                <SideBar onClose={onClose} isOpen={isOpen} />
              </Box>
            </DrawerContent>
          </Drawer>
        </Box>

        <Box flexGrow={1} overflow="auto">
          <Outlet />
        </Box>
      </Box>
    </div>
  );
};

export default SimpleSidebar;
