import { useLocation } from "react-router-dom";
import React from "react";

export enum UserPermission {
  CREATE_USER = 0x0001,
  DELETE_USER = 0x0002,
  MODIFY_USER = 0x0004,
  CREATE_CLINIC = 0x0008,
  DELETE_CLINIC = 0x0010,
  MODIFY_CLINIC = 0x0020,
}

export const checkPermissions = (
  userPermissions: number,
  permission: UserPermission
): Boolean => {
  return (userPermissions & permission) === permission;
};

export const readEnv = (key: string, fallback = ""): string =>
  process.env[key] ?? fallback;

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
