import { Navigate, useLocation } from "react-router-dom";

// if the first character of the pat is a `/` remove it
export const formatPathNameForRedirect = (originalPath: string): string => {
  if (originalPath.charAt(0) === "/") {
    return originalPath.substring(1);
  } else {
    return originalPath;
  }
};

const RedirectToLoginWithPath: React.FC = () => {
  const redirectPath = formatPathNameForRedirect(useLocation().pathname);
  const path = `/login?redirect=${encodeURIComponent(`${redirectPath}`)}`;

  return <Navigate to={path} replace />;
};
export default RedirectToLoginWithPath;
