import { readEnv } from "../utils/functions";

const useMockBackend = readEnv("REACT_APP_BACKEND_TYPE") === "mock";
const apiHost = readEnv("REACT_APP_API_HOST");
const sentryDsn = readEnv("REACT_APP_SENTRY_DSN");
// it must have REACT_APP
const reCaptchaKey = readEnv("REACT_APP_RECAPTCHA_KEY");

const config = {
  useMockBackend,
  apiHost,
  sentryDsn,
  reCaptchaKey,
};

export default config;
