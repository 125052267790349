import { Box, Heading, Text, Button } from "@chakra-ui/react";
import { Link as RouteLink } from "react-router-dom";
import Page from "../../components/Page";

const NotFoundView: React.FC = () => {
  return (
    <Page title="Not Found View">
      <Box textAlign="center" py={10} px={6}>
        <Heading
          display="inline-block"
          as="h2"
          size="2xl"
          bgGradient="linear(to-r, teal.400, teal.600)"
          backgroundClip="text"
        >
          404
        </Heading>
        <Text fontSize="18px" mt={3} mb={2}>
          Page Not Found
        </Text>
        <Text color={"gray.500"} mb={6}>
          The page you're looking for does not seem to exist
        </Text>
        <RouteLink to={"/"}>
          <Button
            colorScheme="teal"
            bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
            color="white"
            variant="solid"
          >
            Go to Home
          </Button>
        </RouteLink>
      </Box>
    </Page>
  );
};

export default NotFoundView;
