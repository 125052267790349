import * as React from "react";
import * as ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";
import "@hookstate/devtools";
import { configure } from "@hookstate/core";
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import config from "./config";
import { App } from "./App";

configure({
  interceptDependencyListsMode: "always",
  isDevelopmentMode: process.env.NODE_ENV === "development",
});

if (config.sentryDsn) {
  Sentry.init({ dsn: config.sentryDsn });
}

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);
