import { ChakraProvider, theme } from "@chakra-ui/react";
import { useRoutes } from "react-router-dom";
import { authenticatedRoutes, clinicAuthenticatedRoutes, guestRoutes } from "./route/route";
import { useLoginState } from "./hookstate/loginState";
import {
  ErrorNotification,
  SuccessNotification,
} from "./components/Notifications/Notifications";
import { isTokenValid } from "./helpers";
import "./App.css"

export const App = () => {
  const state = useLoginState();

  if(!state.jwt) {
    state.callLogOut();
  }

  const currentRoutes = isTokenValid(state.session ?? "")
    ? state.jwt.userPermissions === 4294967295
      ? authenticatedRoutes
      : clinicAuthenticatedRoutes
    : guestRoutes;

  const routing = useRoutes(currentRoutes);
  return (
    <ChakraProvider theme={theme}>
      {routing}
      <ErrorNotification />
      <SuccessNotification />
    </ChakraProvider>
  );
};
