import config from "../config";
import { JWTPayload } from "../api/Types";

export const parseJwt = (token: string): JWTPayload => {
  let base64Url: string = token.split(".")[1];
  let base64: string = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload: string = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const isTokenValid = (token: string): boolean => {
  if (config.useMockBackend) {
    return !!token;
  }

  try {
    // decode token and ensure that it has not expired yet
    const decoded = parseJwt(token);

    return !(decoded === undefined || decoded.exp < Date.now() / 1000);
  } catch (e) {
    return false;
  }
};

export const decodedJwt = (token: string): JWTPayload => {
  const decoded = parseJwt(token);
  return decoded;
};
