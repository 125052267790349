import { Persistence } from "@hookstate/persistence";
import { createState, useHookstate } from "@hookstate/core";
import { api } from "./apiState";
import { useLoginState } from "../hookstate/loginState";
import { useGlobalState } from "./globalState";

export type Clinic = {
  id: number;
  name: string;
  suburb: string;
  state: string;
  country: string;
  postcode: string;
};

export interface clinicsRes {
  offset: number;
  totalResults: number;
  clinics: Clinic[];
}

const initialClinicState: clinicsRes = {
  offset: 0,
  totalResults: 0,
  clinics: [],
};

const clinicState = createState<clinicsRes>(initialClinicState);

export function useClinicState() {
  const clinicsState = useHookstate(clinicState);
  const globalState = useGlobalState();

  const loginState = useLoginState();

  // save data to localstorage
  clinicsState.attach(Persistence("clinic-state"));

  return {
    get Clinics() {
      return clinicsState.clinics.get();
    },

    callGetClinicsApi: async (limit?: number, page?: number): Promise<any> => {
      try {
        if (!loginState.session) {
          globalState.setErrorNotification(
            "Error retrieving token from state",
            5
          );
        } else {
          const clinicResponse = await api.getClinics(
            loginState.session,
            limit,
            page
          );
          const { success, payload } = clinicResponse;

          // array merge or set...
          if (success) {
            clinicsState.set({
              ...payload,
            });
            return clinicsState.get();
          }
        }
      } catch (e) {
        globalState.setErrorNotification("Error retriving clinics", 5);
      }
    },

    callGetSortedClinicsAPI: async (
      limit?: number,
      page?: number,
      sort?: string,
      order?: string
    ): Promise<any> => {
      try {
        if (!loginState.session) {
          globalState.setErrorNotification(
            "Error retrieving token from state",
            5
          );
        } else {
          const clinicResponse = await api.getSortedClinics(
            loginState.session,
            limit,
            page,
            sort,
            order
          );
          const { success, payload } = clinicResponse;

          // array merge or set...
          if (success) {
            clinicsState.set({
              ...payload,
            });
            return clinicsState.get();
          }
        }
      } catch (e) {
        globalState.setErrorNotification("Error retriving clinics", 5);
      }
    },

    callCreateClinicApi: async (
      name: string,
      suburb: string,
      state: string,
      country: string,
      postcode: string,
      recaptchaToken: string,
      callback?: () => void
    ): Promise<any> => {
      try {
        if (!loginState.session) {
          globalState.setErrorNotification(
            "Error retrieving token from state",
            5
          );
        } else {
          const createcClinicResponse = await api.createClinic(
            loginState.session,
            name,
            suburb,
            state,
            country,
            postcode,
            recaptchaToken
          );

          const { success, payload } = createcClinicResponse;

          // array merge or set...
          if (success) {
            callback && callback();
            globalState.setSuccessNotification("Successfully created clinic", 5);
            return clinicsState.merge(payload);
          }
        }
      } catch (e) {
        globalState.setErrorNotification("Error creating clinic", 5);
      }
    },

    callEditClinicApi: async (
      id: number,
      name: string,
      suburb: string,
      state: string,
      country: string,
      postcode: string,
      recaptchaToken: string,
      callback?: () => void
    ): Promise<any> => {
      try {
        if (!loginState.session) {
          globalState.setErrorNotification(
            "Error retrieving token from state",
            5
          );
        } else {
          const createcClinicResponse = await api.editClinic(
            loginState.session,
            id,
            name,
            suburb,
            state,
            country,
            postcode,
            recaptchaToken
          );

          const { success, payload } = createcClinicResponse;

          // array merge or set...
          if (success) {
            callback && callback();
            globalState.setSuccessNotification("Successfully edited clinic", 5);
            return clinicsState.merge(payload);
          }
        }
      } catch (e) {
        globalState.setErrorNotification("Error editing clinic", 5);
      }
    },
  };
}
