import { Clinic } from "./../clinicState";
import { User } from "../userstate";
import { RoleRes } from "../roleState";

export const mockClinics: Clinic[] = [
  {
    id: 1,
    name: "Clinic-1",
    suburb: "Burwood",
    state: "VIC",
    country: "AU",
    postcode: "3125",
  },
  {
    id: 2,
    name: "Clinic-2",
    suburb: "Burwood",
    state: "VIC",
    country: "AU",
    postcode: "3125",
  },
  {
    id: 3,
    name: "Clinic-3",
    suburb: "Burwood",
    state: "VIC",
    country: "AU",
    postcode: "3125",
  },
  {
    id: 4,
    name: "Clinic-4",
    suburb: "Burwood",
    state: "VIC",
    country: "AU",
    postcode: "3125",
  },
];

export const mockRoles: RoleRes = {
  roles: [
    {
      id: 1,
      name: "Admin",
      permissions: 12,
    },
    {
      id: 2,
      name: "Clinic",
      permissions: 128,
    },
  ],
  totalResults: 2,
  offset: 0,
};

export const mockUser: User = {
  id: 1,
  email: "test@test.com",
  name: "test",
  username: "test",
  password: "xxx",
  clinic: {
    id: 1,
    name: "Clinic-1",
    suburb: "Burwood",
    state: "VIC",
    country: "AU",
    postcode: "3125",
  },
  role: {
    id: 1,
    name: "Admin",
    permissions: 12,
  },
};

export const mockUsers: User[] = [
  {
    id: 1,
    email: "test@test.com",
    name: "test",
    username: "test",
    password: "xxx",
    clinic:  {
      id: 1,
      name: "Clinic-1",
      suburb: "Burwood",
      state: "VIC",
      country: "AU",
      postcode: "3125",
    },
    role: {
      id: 1,
      name: "Administrator",
      permissions: 12,
    },
  },
  {
    id: 2,
    email: "test2@test.com",
    name: "test2",
    username: "test2",
    password: "xxx2",
    clinic:  {
      id: 1,
      name: "Clinic-1",
      suburb: "Burwood",
      state: "VIC",
      country: "AU",
      postcode: "3125",
    },
    role: {
      id: 1,
      name: "Administrator",
      permissions: 12,
    },
  },
  {
    id: 3,
    email: "test3@test.com",
    name: "test3",
    username: "test3",
    password: "xxx3",
    clinic:  {
      id: 1,
      name: "Clinic-1",
      suburb: "Burwood",
      state: "VIC",
      country: "AU",
      postcode: "3125",
    },
    role: {
      id: 1,
      name: "Administrator",
      permissions: 12,
    },
  },
  {
    id: 4,
    email: "test4@test.com",
    name: "test4",
    username: "test4",
    password: "xxx4",
    clinic:  {
      id: 1,
      name: "Clinic-1",
      suburb: "Burwood",
      state: "VIC",
      country: "AU",
      postcode: "3125",
    },
    role: {
      id: 1,
      name: "Administrator",
      permissions: 12,
    },
  },
  {
    id: 1,
    email: "test5@test.com",
    name: "test5",
    username: "test5",
    password: "xxx5",
    clinic:  {
      id: 1,
      name: "Clinic-1",
      suburb: "Burwood",
      state: "VIC",
      country: "AU",
      postcode: "3125",
    },
    role: {
      id: 1,
      name: "Administrator",
      permissions: 12,
    },
  },
  {
    id: 6,
    email: "test6@test.com",
    name: "test6",
    username: "test6",
    password: "xxx6",
    clinic:  {
      id: 1,
      name: "Clinic-1",
      suburb: "Burwood",
      state: "VIC",
      country: "AU",
      postcode: "3125",
    },
    role: {
      id: 1,
      name: "Administrator",
      permissions: 12,
    },
  },
  {
    id: 7,
    email: "test7@test.com",
    name: "test7",
    username: "test7",
    password: "xxx7",
    clinic:  {
      id: 1,
      name: "Clinic-1",
      suburb: "Burwood",
      state: "VIC",
      country: "AU",
      postcode: "3125",
    },
    role: {
      id: 2,
      name: "Clinic",
      permissions: 128,
    },
  },
  {
    id: 1,
    email: "test8@test.com",
    name: "test8",
    username: "test8",
    password: "xxx78",
    clinic: {
      id: 2,
      name: "Clinic-2",
      suburb: "Burwood",
      state: "VIC",
      country: "AU",
      postcode: "3125",
    },
    role: {
      id: 2,
      name: "Clinic",
      permissions: 128,
    },
  },
  {
    id: 9,
    email: "test9@test.com",
    name: "test9",
    username: "test9",
    password: "xxx9",
    clinic: {
      id: 2,
      name: "Clinic-2",
      suburb: "Burwood",
      state: "VIC",
      country: "AU",
      postcode: "3125",
    },
    role: {
      id: 2,
      name: "Clinic",
      permissions: 128,
    },
  },
  {
    id: 10,
    email: "test00@test.com",
    name: "test00",
    username: "test00",
    password: "xxx00",
    clinic: {
      id: 2,
      name: "Clinic-2",
      suburb: "Burwood",
      state: "VIC",
      country: "AU",
      postcode: "3125",
    },
    role: {
      id: 2,
      name: "Clinic",
      permissions: 128,
    },
  },
  {
    id: 11,
    email: "test11@test.com",
    name: "test11",
    username: "test11",
    password: "xxx11",
    clinic: {
      id: 3,
      name: "Clinic-3",
      suburb: "Burwood",
      state: "VIC",
      country: "AU",
      postcode: "3125",
    },
    role: {
      id: 2,
      name: "Clinic",
      permissions: 128,
    },
  },
  {
    id: 12,
    email: "test12@test.com",
    name: "test12",
    username: "test12",
    password: "xxx12",
    clinic: {
      id: 3,
      name: "Clinic-3",
      suburb: "Burwood",
      state: "VIC",
      country: "AU",
      postcode: "3125",
    },
    role: {
      id: 2,
      name: "Clinic",
      permissions: 128,
    },
  },
  {
    id: 13,
    email: "test13@test.com",
    name: "test13",
    username: "test13",
    password: "xxx13",
    clinic: {
      id: 3,
      name: "Clinic-3",
      suburb: "Burwood",
      state: "VIC",
      country: "AU",
      postcode: "3125",
    },
    role: {
      id: 1,
      name: "Administrator",
      permissions: 12,
    },
  },
  {
    id: 14,
    email: "test14@test.com",
    name: "test14",
    username: "test14",
    password: "xxx14",
    clinic: {
      id: 3,
      name: "Clinic-3",
      suburb: "Burwood",
      state: "VIC",
      country: "AU",
      postcode: "3125",
    },
    role: {
      id: 1,
      name: "Administrator",
      permissions: 12,
    },
  },
  {
    id: 15,
    email: "test15@test.com",
    name: "test15",
    username: "test15",
    password: "xxx15",
    clinic: {
      id: 4,
      name: "Clinic-4",
      suburb: "Burwood",
      state: "VIC",
      country: "AU",
      postcode: "3125",
    },
    role: {
      id: 2,
      name: "Clinic",
      permissions: 128,
    },
  },
];

export const mockToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyLCJzZXNzaW9uSUQiOiIxMjMiLCJlbWFpbCI6InRlc3RAb3B1c3YuY29tLmF1IiwidXNlclBlcm1pc3Npb25zIjoyLCJleHAiOjE5MjE2ODQzNTIsIm5iZiI6MTUxNjIzOTAyMn0.WMbr7AUbRkno4MKmdsO7jmHxDE1XEpcWqjYXnprOcj4";
