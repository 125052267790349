import { mockRoles } from "./testdata/index";
import axios from "axios";
import { APIResult } from "./../api/Types";
import { APIGetRequest, APIPostRequest, APIPostRequestJSON } from "../api/Common";
import { mockClinics, mockUsers, mockToken } from "./testdata";
import config from "../config";

export const realApi = {
  login: async (
    email: string,
    password: string,
    recaptchaToken: string
  ): Promise<{
    success: boolean;
    data: { payload: { session: string; error: string } };
  }> => {
    const params = new URLSearchParams();
    params.append("email", email);
    params.append("password", password);
    params.append("recaptchaToken", recaptchaToken);

    const apiResponse = await axios
      .post(`${config.apiHost}/api/v1/login`, params, {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      })
      .then((data) => data)
      .catch((e) => {
        throw new Error("Error Logging in");
      });

    return {
      success: true,
      data: { payload: { ...apiResponse.data, error: "" } },
    };
  },

  forgotPassword: async (
    email: string,
    recaptchaToken: string
  ): Promise<{
    success: boolean;
    data: { payload: { error: string } };
  }> => {
    const params = new URLSearchParams();
    params.append("email", email);
    params.append("recaptchaToken", recaptchaToken);

    const apiResponse = await axios
      .post(`${config.apiHost}/api/v1/forgot-password`, params, {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      })
      .then((data) => data)
      .catch((e) => {
        throw new Error("Error sending reset password email");
      });

    return {
      success: true,
      data: { payload: { ...apiResponse.data, error: "" } },
    };
  },

  resetPassword: async (
    t: string,
    password: string,
    verifyPassword: string,
    recaptchaToken: string
  ): Promise<{
    success: boolean;
    data: { payload: { error: string } };
  }> => {
    const params = new URLSearchParams();
    params.append("t", t)
    params.append("password", password);
    params.append("verifyPassword", verifyPassword);
    params.append("recaptchaToken", recaptchaToken);

    const apiResponse = await axios
      .post(`${config.apiHost}/api/v1/reset-password`, params, {
        headers: { 
          "content-type": "application/x-www-form-urlencoded"
        },
      })
      .then((data) => data)
      .catch((e) => {
        throw new Error("Error resetting password");
      });

    return {
      success: true,
      data: { payload: { ...apiResponse.data, error: "" } },
    };
  },

  editUserPassword: async (
    token: string,
    oldPassword: string,
    newPassword: string,
    newPasswordConfirm: string,
    recaptchaToken: string
  ): Promise<APIResult> => {
    const params = new URLSearchParams();
    params.append("oldPassword", oldPassword);
    params.append("newPassword", newPassword);
    params.append("newPasswordConfirm", newPasswordConfirm);
    params.append("recaptchaToken", recaptchaToken);

    const apiResponse = await APIPostRequest(
      "/api/v1/edit-user-password",
      token,
      params
    )

    if (!apiResponse.success) {
      throw new Error("Error editing user password");
    }

    return apiResponse;
  },

  getRoles: async (
    token: string,
    limit?: number,
    page?: number
  ): Promise<APIResult> => {
    const params = new URLSearchParams();
    if (page) {
      params.append("page", page.toString());
    }
    if (limit) {
      params.append("limit", limit.toString());
    }

    const url = "/api/v1/roles";

    const apiResponse = await APIGetRequest(
      url,
      token,
      {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      },
      params
    );

    if (!apiResponse.success) {
      throw new Error("Error Retrieving Roles");
    }

    return apiResponse;
  },

  getAutoComplete: async (
    token: string,
    searchQuery: string
  ): Promise<APIResult> => {
    // ?query=${searchQuery}
    const url = `/api/v1/autocomplete/clinics`;
    const params = new URLSearchParams();
    params.append("query", searchQuery.toString());

    const apiResponse = await APIGetRequest(
      url,
      token,
      {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      },
      params
    );

    if (!apiResponse.success) {
      throw new Error("Error Retrieving Auto Clinics");
    }

    return apiResponse;
  },

  getUsers: async (
    token: string,
    limit?: number,
    page?: number
  ): Promise<APIResult> => {
    const apiResponse = await APIGetRequest(
      `/api/v1/users?limit=${limit}&page=${page}`,
      token,
      {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }
    );

    if (!apiResponse.success) {
      throw new Error("Error Retrieving Users");
    }

    return apiResponse;
  },

  getClinics: async (
    token: string,
    limit?: number,
    page?: number
  ): Promise<APIResult> => {
    const url = "/api/v1/clinics";

    const params = new URLSearchParams();
    if (page) {
      params.append("page", page.toString());
    }
    if (limit) {
      params.append("limit", limit.toString());
    }

    const apiResponse = await APIGetRequest(url, token, undefined, params);

    if (!apiResponse.success) {
      throw new Error("Error Retrieving Clinics");
    }

    return apiResponse;
  },

  getClinic: async(
    token: string,
    id: string
  ): Promise<APIResult> => {
    let url = `/api/v1/clinics/${id}`;
    if (id === "-1") {
      url = `/api/v1/clinics/me`;
    }

    const apiResponse = await APIGetRequest(url, token);

    if (!apiResponse.success) {
      throw new Error("Error Retrieving Clinic");
    }

    return apiResponse;
  },

  getSortedClinics: async (
    token: string,
    limit?: number,
    page?: number,
    sort?: string,
    order?: string
  ): Promise<APIResult> => {
    const url = "/api/v1/clinics";

    const params = new URLSearchParams();
    if (page) {
      params.append("page", page.toString());
    }
    if (limit) {
      params.append("limit", limit.toString());
    }
    if (sort) {
      params.append("sort", sort.toString());
    }
    if (order) {
      params.append("order", order.toString());
    }

    const apiResponse = await APIGetRequest(url, token, undefined, params);

    if (!apiResponse.success) {
      throw new Error("Error Retrieving Clinics");
    }

    return apiResponse;
  },

  createUser: async (
    token: string,
    name: string,
    username: string,
    email: string,
    password: string,
    clinicID: string,
    roleID: string,
    recaptchaToken: string
  ): Promise<APIResult> => {
    const params = new URLSearchParams();
    params.append("email", email);
    params.append("password", password);
    params.append("recaptchaToken", recaptchaToken);
    params.append("username", username);
    params.append("clinicID", clinicID);
    params.append("roleID", roleID);
    params.append("name", name);

    const apiResponse = await APIPostRequest(`/api/v1/users`, token, params, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    });

    if (!apiResponse.success) {
      throw new Error("Error Creating Users");
    }
    return apiResponse;
  },

  editUser: async (
    token: string,
    id: number,
    name: string,
    username: string,
    email: string,
    clinicID: string,
    roleID: string,
    recaptchaToken: string
  ): Promise<APIResult> => {
    const params = new URLSearchParams();
    params.append("email", email);
    params.append("recaptchaToken", recaptchaToken);
    params.append("username", username);
    params.append("clinicID", clinicID);
    params.append("roleID", roleID);
    params.append("name", name);

    const apiResponse = await APIPostRequest(`/api/v1/users/${id}`, token, params, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    });

    if (!apiResponse.success) {
      throw new Error("Error Creating Users");
    }
    return apiResponse;
  },

  // Clinic
  createClinic: async (
    token: string,
    name: string,
    suburb: string,
    state: string,
    country: string,
    postcode: string,
    recaptchaToken: string
  ): Promise<APIResult> => {
    const params = new URLSearchParams();
    params.append("name", name);
    params.append("suburb", suburb);
    params.append("recaptchaToken", recaptchaToken);
    params.append("state", state);
    params.append("country", country);
    params.append("postcode", postcode);

    const apiResponse = await APIPostRequest(`/api/v1/clinics`, token, params, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    });

    if (!apiResponse.success) {
      throw new Error("Error Creating clinics");
    }

    return apiResponse;
  },

  editClinic: async (
    token: string,
    id: number,
    name: string,
    suburb: string,
    state: string,
    country: string,
    postcode: string,
    recaptchaToken: string
  ): Promise<APIResult> => {
    const params = new URLSearchParams();
    params.append("name", name);
    params.append("suburb", suburb);
    params.append("recaptchaToken", recaptchaToken);
    params.append("state", state);
    params.append("country", country);
    params.append("postcode", postcode);

    const apiResponse = await APIPostRequest(`/api/v1/clinics/${id}`, token, params, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    });

    if (!apiResponse.success) {
      throw new Error("Error Creating clinics");
    }

    return apiResponse;
  },

  createExportCommandForClinicID: async(token: string, clinicID: number): Promise<APIResult> => {
    let apiPayload = JSON.stringify({
      clinicID: clinicID,
      command: "DO_EXTRACTION"
    })
    const apiResponse = await APIPostRequestJSON(`/api/v1/command-create`, token, apiPayload, {
      headers: { "Content-Type": "application/json" },
    });

    if (!apiResponse.success) {
      throw new Error("Error Creating Export Command");
    }

    return apiResponse;
  },

  getProjectMetadata: async (token: string): Promise<APIResult> => {
    const apiResponse = await APIGetRequest(`/api/v1/project-metadata`, token);

    if (!apiResponse.success) {
      throw new Error("Error Retrieving Project Metadata");
    }

    return apiResponse;
  },

  getAllRecentCommands: async (token: string): Promise<APIResult> => {
    const apiResponse = await APIGetRequest(`/api/v1/all-recent-commands`, token);

    if (!apiResponse.success) {
      throw new Error("Error Retrieving Recent Commands");
    }

    return apiResponse;
  },

  getPendingRecentCommands: async (token: string): Promise<APIResult> => {
    const apiResponse = await APIGetRequest(`/api/v1/all-pending-commands`, token);

    if (!apiResponse.success) {
      throw new Error("Error Retrieving Pending Commands");
    }

    return apiResponse;
  }
};

export const mockApi = {
  login: async (
    email: string,
    password: string
  ): Promise<{
    success: boolean;
    data: { payload: { session: string; error: string } };
  }> => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (email === "test@opusv.com.au" && password === "hunter2") {
      return Promise.resolve({
        success: true,
        data: {
          payload: { session: mockToken, error: "false" },
        },
      });
    } else {
      throw new Error("Login failed");
    }
  },

  forgotPassword: async (
    email: string,
    recaptchaToken: string
  ): Promise<{
    success: boolean;
    data: { payload: { error: string } };
  }> => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (email === "test@opusv.com.au" ) {
      return Promise.resolve({
        success: true,
        data: {
          payload: { error: "false" },
        }
      });
    } else {
      throw new Error("Forgot password failed");
    }
  },

  resetPassword: async (
    t: string,
    password: string,
    verifyPassword: string,
    recaptchaToken: string
  ): Promise<{
    success: boolean;
    data: { payload: { error: string } };
  }> => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (password === verifyPassword) {
      return Promise.resolve({
        success: true,
        data: {
          payload: { error: "false" },
        }
      });
    } else {
      throw new Error("Password reset failed");
    }
  },

  editUserPassword: async (
    token: string,
    oldPassword: string,
    newPassword: string,
    newPasswordConfirm: string,
    recaptchaToken: string
  ): Promise<APIResult> => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (oldPassword === "hunter2" && newPassword === newPasswordConfirm) {
      return Promise.resolve({
        success: true,
        payload: {
          error: "false",
        }
      });
    } else {
      throw new Error("Password edit failed");
    }
  },

  getUsers: async (
    token: string,
    limit?: number,
    page?: number
  ): Promise<APIResult> => {
    const limitToUse = limit ? limit : 25;
    const pageToUse = page && page > 0 ? page : 0;

    const lowerBound = limitToUse * pageToUse;
    const upperBound = limitToUse * (pageToUse + 1);
    const users = mockUsers.filter((user, index) => {
      return index >= lowerBound && index < upperBound;
    });

    return Promise.resolve({
      success: true,
      payload: {
        users,
        totalResults: mockUsers.length,
        offset: 0,
      },
    });
  },

  getClinics: async (
    token: string,
    limit?: number,
    page?: number
  ): Promise<APIResult> => {
    const limitToUse = limit ? limit : 25;
    const pageToUse = page && page > 0 ? page : 0;

    const lowerBound = limitToUse * pageToUse;
    const upperBound = limitToUse * (pageToUse + 1);
    const clinics = mockClinics.filter((user, index) => {
      return index >= lowerBound && index < upperBound;
    });

    return Promise.resolve({
      success: true,
      payload: {
        clinics,
        totalResults: mockClinics.length,
        offset: 0,
      },
    });
  },

  getSortedClinics: async (
    token: string,
    limit?: number,
    page?: number,
    sort?: string,
    order?: string
  ): Promise<APIResult> => {
    const limitToUse = limit ? limit : 25;
    const pageToUse = page && page > 0 ? page : 0;

    const lowerBound = limitToUse * pageToUse;
    const upperBound = limitToUse * (pageToUse + 1);
    const clinics = mockClinics.filter((user, index) => {
      return index >= lowerBound && index < upperBound;
    });

    return Promise.resolve({
      success: true,
      payload: {
        clinics,
        totalResults: mockClinics.length,
        offset: 0,
      },
    });
  },

  createUser: async (): Promise<APIResult> => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return Promise.resolve({ success: true, payload: mockUsers[0] });
  },

  editUser: async (): Promise<APIResult> => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return Promise.resolve({ success: true, payload: mockUsers[0] });
  },

  createClinic: async (): Promise<APIResult> => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return Promise.resolve({ success: true, payload: mockClinics[0] });
  },

  editClinic: async (): Promise<APIResult> => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return Promise.resolve({ success: true, payload: mockClinics[0] });
  },

  getClinic: async (): Promise<APIResult> => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return Promise.resolve({ success: true, payload: mockClinics[0] });
  },

  // mock roles data - >
  getRoles: async (): Promise<APIResult> => {
    return Promise.resolve({ success: true, payload: mockRoles });
  },

  getAutoComplete: async (): Promise<APIResult> => {
    return Promise.resolve({ success: true, payload: mockClinics });
  },

  createExportCommandForClinicID: async(token: string, userID: number): Promise<APIResult> => {
    return Promise.resolve({ success: true, payload: {} });
  },

  getProjectMetadata: async (token: string): Promise<APIResult> => {
    return Promise.resolve({ success: true, payload: {} });
  },

  getAllRecentCommands: async (token: string): Promise<APIResult> => {
    return Promise.resolve({ success: true, payload: {} });
  },

  getPendingRecentCommands: async (token: string): Promise<APIResult> => {
    return Promise.resolve({ success: true, payload: {} });
  },
};

export const api = config.useMockBackend ? mockApi : realApi;
