import { FC, useEffect, useState } from 'react';
import Page from "../../components/Page";
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
} from "@chakra-ui/react";
import { api } from '../../hookstate/apiState';
import { useLoginState } from '../../hookstate/loginState';
import { Link as RouterLink } from 'react-router-dom';

type Clinic = {
  id: number;
  name: string;
  address: string;
  suburb: string;
  state: string;
  postcode: string;
  totalPatients: number;
  totalExams: number;
  lastExportTime: number;
  exportPending: false;
}

type Command = {
  commandID: string;
  command: string;
  responseCommand: boolean;
  responseCommandError: string;
  timeIssued: number;
  timeReceived: number;
  timeCompleted: number;
  clinic: Clinic;
}

const Dashboard: FC = () => {
  const loginState = useLoginState();
  const [totalClinics, setTotalClinics] = useState<number>(-1);
  const [totalUsers, setTotalUsers] = useState<number>(-1);
  const [totalPatients, setTotalPatients] = useState<number>(-1);
  const [totalPatientRecords, setTotalPatientRecords] = useState<number>(-1);
  const [allRecentCommands, setAllRecentCommands] = useState<Command[]>([]);
  const [pendingRecentCommands, setPendingRecentCommands] = useState<Command[]>([]);

  useEffect(() => {
    if(loginState.session) {
      api.getProjectMetadata(loginState.session).then((res) => {
        setTotalClinics(res.payload.totalClinics);
        setTotalUsers(res.payload.totalUsers);
        setTotalPatients(res.payload.totalPatients);
        setTotalPatientRecords(res.payload.totalExams);
      });

      api.getAllRecentCommands(loginState.session).then((res) => {
        setAllRecentCommands(res.payload);
      });

      api.getPendingRecentCommands(loginState.session).then((res) => {
        setPendingRecentCommands(res.payload);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page title="Dashboard View">
      <Flex textAlign="center" padding="20px" flexWrap="wrap" gap="20px">
      <Box width="20%" border="1px solid #CCC">
          <Box padding="10px" backgroundColor="#285FAA">
            <Text variant="h3" color="#FFF" fontWeight="bold">Information Overview</Text>
          </Box>
          <Box>
            <Table size="md">
              <Tbody>
                <Tr>
                  <Th width="50%" textAlign="right">Total Clinics:</Th>
                  <Td textAlign="right">{totalClinics === -1 ? "Loading" : totalClinics ?? 0}</Td>
                </Tr>
                <Tr>
                  <Th textAlign="right">Total Users:</Th>
                  <Td textAlign="right">{totalUsers === -1 ? "Loading" : totalUsers ?? 0}</Td>
                </Tr>
                <Tr>
                  <Th textAlign="right">Total Patients:</Th>
                  <Td textAlign="right">{totalPatients === -1 ? "Loading" : totalPatients ?? 0}</Td>
                </Tr>
                <Tr>
                  <Th textAlign="right">Total Patient Records:</Th>
                  <Td textAlign="right">{totalPatientRecords === -1 ? "Loading" : totalPatientRecords ?? 0}</Td>
                </Tr>
                </Tbody>
            </Table>
          </Box>
        </Box>

        <Box width="75%" flexGrow={1} border="1px solid #CCC">
          <Box padding="10px" backgroundColor="#285FAA">
            <Text variant="h3" color="#FFF" fontWeight="bold">Latest Task Issued</Text>
          </Box>
          <Box padding="5px">
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Clinic</Th>
                  <Th>Task</Th>
                  <Th>Issued On</Th>
                  <Th>Completed On</Th>
                  <Th>Successful</Th>
                </Tr>
              </Thead>
              <Tbody>
                {allRecentCommands.map((command) => (
                  <Tr key={command.commandID}>
                    <Td><RouterLink to={`/clinics/${command.clinic.id}/view`}>{command.clinic.name}</RouterLink></Td>
                    <Td>{command.command}</Td>
                    <Td>{new Date(command.timeIssued * 1000).toLocaleString()}</Td>
                    <Td>{new Date(command.timeCompleted * 1000).toLocaleString()}</Td>
                    <Td>{command.responseCommand ? "Yes" : "No"}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>

        <Box width="50%" border="1px solid #CCC">
          <Box padding="10px" backgroundColor="#285FAA">
            <Text variant="h3" color="#FFF" fontWeight="bold">Pending Tasks</Text>
          </Box>
          <Box padding="5px">
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Clinic</Th>
                  <Th>Task</Th>
                  <Th>Issued On</Th>
                </Tr>
              </Thead>
              <Tbody>
                {pendingRecentCommands.length > 0 ? pendingRecentCommands.map((command) => (
                    <Tr key={command.commandID}>
                      <Td><RouterLink to={`/clinics/${command.clinic.id}/view`}>{command.clinic.name}</RouterLink></Td>
                      <Td>{command.command}</Td>
                      <Td>{new Date(command.timeIssued * 1000).toLocaleString()}</Td>
                    </Tr>
                  )): <Tr><Td textAlign={'center'} colSpan={3}>No pending tasks</Td></Tr>}
              </Tbody>
            </Table>
          </Box>
        </Box>
      </Flex>
    </Page>
  );
};

export default Dashboard;
