import {
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  BoxProps,
  FlexProps,
  Show,
} from "@chakra-ui/react";
import React, {  useState } from "react";
import { FiHome, FiTrendingUp, FiCompass } from "react-icons/fi";
import { IconType } from "react-icons";
import { ReactText } from "react";
import { Link as RouteLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "./sideBar.css";
import { useLoginState } from "../../hookstate/loginState";

interface LinkItemProps {
  name: string;
  icon: IconType;
  to: string;
  permissionsRequired?: number;
}
const LinkItems: Array<LinkItemProps> = [
  { name: "Home", icon: FiHome, to: "/dashboard" },
  { name: "Clinics", icon: FiTrendingUp, to: "/clinics", permissionsRequired: 4294967295 },
  { name: "Users", icon: FiCompass, to: "/users", permissionsRequired: 4294967295 },
];

interface SidebarProps extends BoxProps {
  onClose: () => void;
  isOpen: boolean;
}

const SideBar: React.FC<SidebarProps> = ({ isOpen, onClose, ...rest }) => {
  const currentPathname = useLocation().pathname;
  const [activeSidebarPath, setActiveSidebarPath] = useState<string>(currentPathname);
  const loginState = useLoginState();

  return (
    <Box
      zIndex={99}
      borderRight="1px"
      top="64px"
      bg={useColorModeValue("white", "gray.900")}
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      h="full"
      backgroundColor={"#F9F9F9"}
      {...rest}
    >
      <Show below="md">
        <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
          <CloseButton
            display={{ base: "flex", md: "none" }}
            onClick={onClose}
          />
        </Flex>
      </Show>

      {LinkItems.map((link) => {
        if(link.permissionsRequired && link.permissionsRequired !== loginState.jwt.userPermissions) {
          return null;
        }

        return (
          <RouteLink style={{ textDecoration: "none" }} onClick={() => { onClose(); setActiveSidebarPath(link.to); }} to={link.to} key={link.name}>
            <NavItem
              name={link.name}
              activeSidebarPath={activeSidebarPath}
              setActiveSidebarPath={setActiveSidebarPath}
              icon={link.icon}
              linkPath={link.to}
            >
              {link.name}
            </NavItem>
          </RouteLink>
        );
        })}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  name: string;
  activeSidebarPath: string;
  linkPath: string;
  setActiveSidebarPath: React.Dispatch<React.SetStateAction<string>>;
  children: ReactText;
}
const NavItem = ({
  icon,
  name,
  activeSidebarPath,
  linkPath,
  setActiveSidebarPath,
  children,
  ...rest
}: NavItemProps) => {
  return (
    <Flex
      align="center"
      p="4"
      role="group"
      cursor="pointer"
      _hover={{
        backgroundColor: "#73B973",
        color: "white",
      }}
      className={activeSidebarPath === linkPath ? "active" : ""}
      {...rest}
    >
      {icon && (
        <Icon
          mr="4"
          fontSize="16"
          _groupHover={{
            color: "white",
          }}
          as={icon}
        />
      )}
      {children}
    </Flex>
  );
};

export default SideBar;
