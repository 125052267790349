import React from "react";
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  CloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useGlobalState } from "../../hookstate/globalState";

export const SuccessNotification: React.FC = () => {
  const globalState = useGlobalState();

  const { isOpen, onClose } = useDisclosure({
    isOpen: globalState.global.successMsg.status,
    defaultIsOpen: false,
  });

  return isOpen ? (
    <Box position="fixed" bottom="80px" right="20px">
      <Alert status="success">
        <AlertIcon />
        <Box>
          <AlertDescription>
            {globalState.global.successMsg.message}
          </AlertDescription>
        </Box>
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          right={-1}
          top={-1}
          onClick={() => {
            globalState.clearSuccessNotification();
            onClose();
          }}
        />
      </Alert>
    </Box>
  ) : null;
};

export const ErrorNotification: React.FC = () => {
  const globalState = useGlobalState();

  const { isOpen, onClose } = useDisclosure({
    isOpen: globalState.global.errorMsg.status,
    defaultIsOpen: false,
  });

  return isOpen ? (
    <Box position="fixed" bottom="80px" right="20px">
      <Alert status="warning">
        <AlertIcon />
        <Box>
          <AlertDescription>
            {globalState.global.errorMsg.message}
          </AlertDescription>
        </Box>
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          right={-1}
          top={-1}
          onClick={() => {
            globalState.clearErrorNotification();
            onClose();
          }}
        />
      </Alert>
    </Box>
  ) : null;
};
