import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Box,
  Flex,
  useColorModeValue,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Text,
  Show,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
// import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { useLoginState } from "../../hookstate/loginState";
import headerLogo from '../../imgs/small-logo.png';
import { ChevronDownIcon } from "@chakra-ui/icons";

interface Props {
  onOpen: React.MouseEventHandler;
  toggleColorMode: React.MouseEventHandler;
  colorMode: string;
}

const TopBar: React.FC<Props> = ({ onOpen, toggleColorMode, colorMode }) => {
  const loginState = useLoginState();

  const navigate = useNavigate();

  return (
    <Box
      bg={useColorModeValue("#285FAA", "#00285A")}
      zIndex={999}
      display="flex"
      flexDirection="column"
      width="100%"
      boxSizing="border-box"
      flexShrink={0}
      top="0px"
      left="auto"
      right="0px"
      height={"60px"}
    >
      <Flex
        position="relative"
        h={16}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Show below="md">
            <IconButton
              mx={2}
              variant="outline"
              onClick={onOpen}
              aria-label="open menu"
              icon={<FiMenu />}
            />
          </Show>
          <Box px={4}>
            <img src={headerLogo} width="60px" alt="header logo" />
          </Box>
        </Box>

        <Flex alignItems={"center"}>
          <Stack direction={"row"} spacing={7} px={6}>
            {/* <Button onClick={toggleColorMode}>
              {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            </Button> */}

            <Menu>
              <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
                rightIcon={<ChevronDownIcon />}
                color={"white"}
                backgroundColor="rgba(255,255,255,0.1)"
                _hover={{ backgroundColor: "rgba(255,255,255,0.2)" }}
                _active={{ backgroundColor: "rgba(255,255,255,0.2)" }}
                padding="5px 10px 5px 10px"
              >
                <Flex
                  alignItems={"center"}
                  alignContent={"center"}
                  columnGap="20px"
                >
                  <Avatar size={"sm"} />
                  <Text fontWeight="normal">{loginState.jwt.email}</Text>
                </Flex>
              </MenuButton>
              <MenuList alignItems={"center"}>
                <MenuItem onClick={() => navigate("edit-password")}>
                  Change Password
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    loginState.callLogOut(() => navigate("/login"));
                  }}
                >
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        </Flex>
      </Flex>
    </Box>
  );
};

export default TopBar;
