import { FC, ChangeEvent, useEffect, useState } from "react";
import {
  Center,
  Select,
  Text,
  Button,
  Stack,
  ChakraProvider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
} from "@chakra-ui/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@chakra-ui/icons";
import {
  Pagination,
  usePagination,
  PaginationNext,
  PaginationPrevious,
  PaginationContainer,
} from "@ajna/pagination";
import CreateUsers from "./CreateUsers";
import { useUserState } from "../../hookstate/userstate";
import { useLoginState } from "../../hookstate/loginState";
import Page from "../../components/Page";
import { checkPermissions, UserPermission } from "../../utils/functions";
import { Link } from "react-router-dom";
import linkStyles from '../../styles/listlink.module.css';
import EditUsers from "./EditUsers";

const UsersView: FC = () => {
  // states
  const [usersTotal, setUserTotal] = useState<number | undefined>(undefined);
  const [users, setUsers] = useState<any[]>([]);

  const userState = useUserState();
  const loginState = useLoginState();

  // constants
  const outerLimit = 2;
  const innerLimit = 2;

  const pageSizeList = [10, 25, 50, 100];

  const {
    pagesCount,
    offset,
    currentPage,
    setCurrentPage,
    isDisabled,
    pageSize,
    setPageSize,
  } = usePagination({
    total: usersTotal,
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
    initialState: {
      pageSize: pageSizeList[0],
      isDisabled: false,
      currentPage: 1,
    },
  });
  // effects
  useEffect(() => {
    userState
      .callGetUsersApi(currentPage - 1, pageSize)
      .then((usersRes) => {
        setUsers(usersRes.users);
        setUserTotal(usersRes.totalResults);
      })
      .catch((error) => {});
  }, [currentPage, pageSize, offset]); // eslint-disable-line react-hooks/exhaustive-deps

  // handlers
  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };

  const handlePageSizeChange = (
    event: ChangeEvent<HTMLSelectElement>
  ): void => {
    const pageSize = Number(event.target.value);

    setPageSize(pageSize);
  };

  return (
    <Page title="Optometry Australia Data Collection Portal - Users">
      <ChakraProvider>
        <Box>
          {loginState.jwt.userPermissions ? (
            checkPermissions(
              loginState.jwt.userPermissions,
              UserPermission.CREATE_USER
            ) && <CreateUsers />
          ) : (
            <Button></Button>
          )}

          <Stack padding="10px 20px">
            <TableContainer minWidth={100}>
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Username</Th>
                    <Th>Email</Th>
                    <Th>Clinic</Th>
                    <Th>Role</Th>
                    <Th>Reset Initial Password</Th>
                    <Th>Completed Onboarding</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {users.length > 0 &&
                    users?.map((user) => {
                      return (
                        <Tr key={`user_${user.id}`}>
                        {/* <Td><Link className={linkStyles.link} to={`/users/${user.id}/view`}>{user.name}</Link></Td>
                        <Td><Link className={linkStyles.link} to={`/users/${user.id}/view`}>{user.username}</Link></Td>
                        <Td><Link className={linkStyles.link} to={`/users/${user.id}/view`}>{user.email}</Link></Td> */}
                          <Td>{user.name}</Td>
                          <Td>{user.username}</Td>
                          <Td>{user.email}</Td>
                          <Td><Link className={linkStyles.link} to={`/clinics/${user.clinic.id}/view`}>{user.clinic.name}</Link></Td>
                          <Td>{user.role.name}</Td>
                          <Td>{user.hasResetInitialPassword ? "Yes" : "No"}</Td>
                          <Td>{user.hasCompletedSetup ? "Yes" : "No"}</Td>
                          <Td><EditUsers user={user} /></Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>

            <Center w="full" paddingTop={5}>
              <Select ml={3} onChange={handlePageSizeChange} w={40}>
                {pageSizeList.map((item) => {
                  return (
                    <option key={item} value={item}>
                      Show {item}
                    </option>
                  );
                })}
              </Select>
            </Center>
            <Pagination
              pagesCount={pagesCount}
              currentPage={currentPage}
              isDisabled={isDisabled}
              onPageChange={handlePageChange}
            >
              <PaginationContainer
                align="center"
                justify="space-between"
                p={4}
                w="full"
              >
                <PaginationPrevious
                  _hover={{
                    bg: "#3C414B",
                  }}
                  bg="#B9BEC3"
                >
                  <ArrowLeftIcon />
                </PaginationPrevious>

                {/*  page of total counts */}
                <Text flexShrink="0" mr={8}>
                  Page{" "}
                  <Text fontWeight="bold" as="span">
                    {currentPage}
                  </Text>{" "}
                  of{" "}
                  <Text fontWeight="bold" as="span">
                    {pagesCount}
                  </Text>
                </Text>
                <PaginationNext
                  _hover={{
                    bg: "#3C414B",
                  }}
                  bg="#B9BEC3"
                >
                  <ArrowRightIcon />
                </PaginationNext>
              </PaginationContainer>
            </Pagination>
          </Stack>
        </Box>
      </ChakraProvider>
    </Page>
  );
};

export default UsersView;
