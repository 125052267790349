import { Persistence } from "@hookstate/persistence";
import { createState, useHookstate } from "@hookstate/core";

export type Error = { message: string; status: boolean };
export type Success = { message: string; status: boolean };

export type GlobalState = {
  successMsg: Success;
  errorMsg: Error;
  loading: boolean;
};

const initState: GlobalState = {
  successMsg: { message: "", status: false },
  errorMsg: { message: "", status: false },
  loading: false,
};

const globalState = createState<GlobalState>(initState);

export function useGlobalState() {
  const state = useHookstate(globalState);

  // save data to localstorage
  state.attach(Persistence("global-state"));

  return {
    get global(): GlobalState {
      return state.get();
    },

    async setSuccessNotification(
      message: string,
      duration: number
    ): Promise<any> {
      state.successMsg.set({
        message,
        status: true,
      });

      setTimeout(() => {
        this.clearSuccessNotification();
      }, duration * 1000);
    },

    async setErrorNotification(
      message: string,
      duration: number
    ): Promise<any> {
      state.errorMsg.set({
        message,
        status: true,
      });
      setTimeout(() => {
        this.clearErrorNotification();
      }, duration * 1000);
    },

    clearSuccessNotification() {
      state.successMsg.set({
        message: "",
        status: false,
      });
    },

    clearErrorNotification() {
      state.errorMsg.set({
        message: "",
        status: false,
      });
    },
  };
}
