import { Persistence } from "@hookstate/persistence";
import { createState, useHookstate } from "@hookstate/core";
import { useLoginState } from "../hookstate/loginState";
import { useGlobalState } from "./globalState";
import { api } from "./apiState";

export type Role = {
  id: number;
  name: string;
  permissions: number;
};

export type RoleRes = {
  offset: number;
  totalResults: number;
  roles: Role[];
};

const initialRoleState: RoleRes = {
  offset: 0,
  totalResults: 0,
  roles: [],
};

const roleState = createState<RoleRes>(initialRoleState);

export function useRoleState() {
  const state = useHookstate(roleState);
  const globalState = useGlobalState();

  const loginState = useLoginState();

  // save data to localstorage
  state.attach(Persistence("role-state"));

  return {
    get Roles() {
      return state.roles.get();
    },

    callGetRolesApi: async (page?: number, limit?: number): Promise<any> => {
      try {
        if (!loginState.session) {
          globalState.setErrorNotification(
            "Error retrieving token from state",
            5
          );
        } else {
          const rolesResponse = await api.getRoles(
            loginState.session,
            limit,
            page
          );
          const { success, payload } = rolesResponse;

          // array merge or set...
          if (success) {
            globalState.setSuccessNotification("Success in retrieving roles", 5);
            state.set({
              ...payload,
            });
            return state.get();
          }
        }
      } catch (e) {
        globalState.setErrorNotification("Error in retriving roles", 5);
      }
    },
  };
}
