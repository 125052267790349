import React from "react";
import * as Sentry from "@sentry/react";

interface ErrorBoundaryProps {
  hasError: boolean;
  error: string;
}
export class ErrorBoundary extends React.Component<any, ErrorBoundaryProps> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.toString() };
  }

  componentDidCatch(error: Error, info: any) {
    Sentry.withScope((scope) => {
      Object.keys(info).forEach((key) => {
        scope.setExtra(key, info[key]);
      });

      if (process.env.NODE_ENV === "production") {
        Sentry.captureException(error);
      }
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <p>Something has gone wrong.</p>
          {this.state.error}
        </div>
      );
    }

    // If there is no error just render the children component.
    return this.props.children;
  }
}
