import { FC } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Stack,
  Box,
  Input,
  FormLabel,
  Select,
  Button,
  useDisclosure,
  FormControl,
} from "@chakra-ui/react";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Clinic, useClinicState } from "../../hookstate/clinicState";
import config from "../../config";

const validationSchema = Yup.object().shape({
  clinicPostcode: Yup.string().required("Postcode is required"),
  clinicCountry: Yup.string().required("Country is required"),
  clinicName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Name is required"),
  clinicState: Yup.string().required("State is required"),
  clinicSuburb: Yup.string().required("Suburb is required"),
});

type EditClinicsContainerProps = {
    id: number,
    clinicName: string,
    clinicPostcode: string,
    clinicState: string,
    clinicCountry: string,
    clinicSuburb: string,
}

const EditClinicsContainer: FC<EditClinicsContainerProps> = (props: EditClinicsContainerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const clinicState = useClinicState();
  const navigate = useNavigate();

  return (
    <>
      <Flex justifyContent="end">
        <Button
          colorScheme="teal"
          backgroundColor="#285FAA"
          onClick={onOpen}
        >
          Edit
        </Button>
      </Flex>
      <Drawer
        size={"lg"}
        isOpen={isOpen}
        placement="right"
        // initialFocusRef={name}
        onClose={onClose}
      >
        <Formik
          initialValues={{
            clinicName: props.clinicName,
            clinicPostcode: props.clinicPostcode,
            clinicState: props.clinicState,
            clinicCountry: props.clinicCountry,
            clinicSuburb: props.clinicSuburb,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, _actions) => {
            if (!executeRecaptcha) {
              return;
            }

            const recaptchaToken = await executeRecaptcha("UserLogin");

            clinicState.callEditClinicApi(
              props.id,
              values.clinicName,
              values.clinicSuburb,
              values.clinicState,
              values.clinicCountry,
              values.clinicPostcode,
              recaptchaToken,
              () => navigate(0)
            );
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">
                  Edit Clinic
                </DrawerHeader>

                <DrawerBody>
                  <Stack spacing="24px">
                    <FormControl>
                      <FormLabel htmlFor="clinicName">Clinic Name</FormLabel>
                      <Input
                        name="clinicName"
                        type="text"
                        onChange={handleChange}
                        value={values.clinicName}
                        placeholder="Clinic Name"
                      />
                      {errors.clinicName && touched.clinicName ? (
                        <Box color={"red"} padding={"1"}>
                          {errors.clinicName}
                        </Box>
                      ) : null}
                    </FormControl>

                    <FormControl>
                      <FormLabel htmlFor="clinicSuburb">Suburb</FormLabel>
                      <Input
                        name="clinicSuburb"
                        type="text"
                        onChange={handleChange}
                        value={values.clinicSuburb}
                        placeholder="Suburb"
                      />
                      {errors.clinicSuburb && touched.clinicSuburb ? (
                        <Box color={"red"} padding={"1"}>
                          {errors.clinicSuburb}
                        </Box>
                      ) : null}
                    </FormControl>

                    <Box>
                      <FormLabel>Select State</FormLabel>
                      <Select
                        name="state"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={props.clinicState}
                      >
                        <option value="ACT">Australian Capital Territory</option>
                        <option value="NSW">New South Wales</option>
                        <option value="NT">Northern Territory</option>
                        <option value="QLD">Queensland</option>
                        <option value="SA">South Australia</option>
                        <option value="TAS">Tasmania</option>
                        <option value="VIC">Victoria</option>
                        <option value="WA">Western Australia</option>
                      </Select>
                    </Box>

                    {/* <FormControl>
                      <FormLabel htmlFor="clinicCountry">Country</FormLabel>
                      <Input
                        name="clinicCountry"
                        type="text"
                        value={"AU"}
                        placeholder="Country"
                      />
                      {errors.country && touched.country ? (
                        <Box color={"red"} padding={"1"}>
                          {errors.country}
                        </Box>
                      ) : null}
                    </FormControl> */}

                    <FormControl>
                      <FormLabel htmlFor="clinicCountry">Country</FormLabel>
                      <Select
                        name="clinicCountry"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="AU">Australia</option>
                      </Select>
                    </FormControl>

                    <FormControl>
                      <FormLabel htmlFor="clinicPostcode">Postcode</FormLabel>
                      <Input
                        name="clinicPostcode"
                        type="text"
                        onChange={handleChange}
                        value={values.clinicPostcode}
                        placeholder="Postcode"
                      />
                      {errors.clinicPostcode && touched.clinicPostcode ? (
                        <Box color={"red"} padding={"1"}>
                          {errors.clinicPostcode}
                        </Box>
                      ) : null}
                    </FormControl>
                  </Stack>
                </DrawerBody>

                <DrawerFooter borderTopWidth="1px">
                <Button variant="outline" mr={3} onClick={onClose}>
                    Cancel
                </Button>
                  <Button
                    borderRadius={5}
                    type="submit"
                    variant="solid"
                    colorScheme="blue"
                  >
                    Submit
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};

type EditClinicsProps = {
    clinic: Clinic;
}

const EditClinics: FC<EditClinicsProps> = (props: EditClinicsProps) => {
    let state: string = "";
    switch (props.clinic.state.toLowerCase()) {
        case "australian capital territory":
        case "act":
            state = "ACT";
            break;
        case "new south wales":
        case "nsw":
            state = "NSW";
            break;
        case "northern territory":
        case "nt":
            state = "NT";
            break;
        case "queensland":
        case "qld":
            state = "QLD";
            break;
        case "south australia":
        case "sa":
            state = "SA";
            break;
        case "tasmania":
        case "tas":
            state = "TAS";
            break;
        case "victoria":
        case "vic":
            state = "VIC";
            break;
        case "western australia":
        case "wa":
            state = "WA";
            break;
        default:
            state = "ACT";
            break;
    }

    let country: string = "";
    switch (props.clinic.country.toLowerCase()) {
        case "australia":
        case "au":
            country = "AU";
            break;
        default:
            country = "AU";
            break;
    }

    return (
        <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
            <EditClinicsContainer id={props.clinic.id} clinicName={props.clinic.name} clinicPostcode={props.clinic.postcode} clinicState={state} clinicCountry={country} clinicSuburb={props.clinic.suburb} />
        </GoogleReCaptchaProvider>
    );
};

export default EditClinics;
