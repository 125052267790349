import { Box,
    Flex,
    Text,
    TableContainer,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import Page from '../../components/Page';
import { api } from '../../hookstate/apiState';
import { useParams } from 'react-router';
import { useLoginState } from '../../hookstate/loginState';
import ClinicIcon from '../../imgs/ClinicIcon.png';
import clinicStyles from '../../styles/clinicview.module.css';
import EditClinics from './EditClinics';
import { User } from '../../hookstate/userstate';
// import { Link } from 'react-router-dom';
// import linkStyles from '../../styles/listlink.module.css';

type UserActivity = {
    userID: number,
	activityType: string,
	activityMeta: string,
	activityTime: number,
}

const ClinicView: FC = () => {
    const { id } = useParams<{id: string}>();
    const loginState = useLoginState();
    const [clinicID, setClinicID] = useState<number>(0);
    const [clinicName, setClinicName] = useState<string>("");
    const [clinicSuburb, setClinicSuburb] = useState<string>("");
    const [clinicState, setClinicState] = useState<string>("");
    const [clinicPostcode, setClinicPostcode] = useState<string>("");
    const [clinicCountry, setClinicCountry] = useState<string>("");
    const [users, setUsers] = useState<User[]>([]);
    const [UserActivity, setUserActivity] = useState<UserActivity[]>([]);

    const parseState = (state: string) => {
        switch(state.toLowerCase()) {
            case "act":
                setClinicState("Australian Capital Territory");
                break;
            case "nsw":
                setClinicState("New South Wales");
                break;
            case "nt":
                setClinicState("Northern Territory");
                break;
            case "qld":
                setClinicState("Queensland");
                break;
            case "sa":
                setClinicState("South Australia");
                break;
            case "tas":
                setClinicState("Tasmania");
                break;
            case "vic":
                setClinicState("Victoria");
                break;
            case "wa":
                setClinicState("Western Australia");
                break;
            default:
                setClinicState(state);
                break;
        }
    }

    const parseCountry = (country: string) => {
        switch(country.toLowerCase()) {
            case "au":
                setClinicCountry("Australia");
                break;
            default:
                setClinicCountry(country);
                break;
        }
    }

    useEffect(() => {
        if (!loginState.session) return;

        if (!id) {
            api.getClinic(loginState.session, "-1")
                .then((res) => {
                    setClinicID(res.payload.id);
                    setClinicName(res.payload.name);
                    setClinicSuburb(res.payload.suburb);
                    parseState(res.payload.state);
                    parseCountry(res.payload.country);
                    setClinicPostcode(res.payload.postcode);

                    if(res.payload.users) setUsers([...res.payload.users]);
                    if(res.payload.latestActivities) setUserActivity([...res.payload.latestActivities])
                })
                .catch((err) => {
                    setClinicID(-1);
                });
            return;
        }

        api.getClinic(loginState.session, id)
            .then((res) => {
                setClinicID(res.payload.id);
                setClinicName(res.payload.name);
                setClinicSuburb(res.payload.suburb);
                parseState(res.payload.state);
                parseCountry(res.payload.country);
                setClinicPostcode(res.payload.postcode);

                if(res.payload.users) setUsers([...res.payload.users]);
                if(res.payload.latestActivities) setUserActivity([...res.payload.latestActivities])
            })
            .catch((err) => {
                setClinicID(-1);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if(clinicID === 0) {
        return (
            <Page title="Optometry Australia Data Collection Portal - Clinics">
                <Flex padding="20px" columnGap="20px" rowGap="20px" flexWrap="wrap">
                    <Text>Loading clinic information...</Text>
                </Flex>
            </Page>
        );
    }

    if(clinicID === -1) {
        return (
            <Page title="Optometry Australia Data Collection Portal - Clinics">
                <Flex padding="20px" columnGap="20px" rowGap="20px" flexWrap="wrap">
                    <Text>Unable to load clinic information</Text>
                </Flex>
            </Page>
        );
    }

    return (
        <Page title={`Optometry Australia Data Collection Portal - ${clinicName}`}>
            <Flex padding="20px" columnGap="20px" rowGap="20px" flexWrap="wrap">
                <Flex columnGap="20px" rowGap="20px" width="50%" flexGrow={1}>
                    <Box padding="20px">
                        <Box width="80px" height="80px" borderRadius="40px" background="#CCC" padding="20px">
                            <img width="40px" height="40px" src={ClinicIcon} alt="Clinic" />
                        </Box>
                    </Box>
                    <Box padding="20px 20px 20px 0px">
                        <Text className={clinicStyles.clinicName}>{clinicName}</Text>
                        <Text className={clinicStyles.clinicDetails}>{clinicSuburb}, {clinicState} {clinicPostcode}</Text>
                        <Text className={clinicStyles.clinicDetails}>{clinicCountry}</Text>
                    </Box>
                </Flex>
                <Flex width="45%" justifyContent="end" columnGap="20px" paddingRight="20px">
                    <EditClinics clinic={{
                        id: clinicID ? clinicID : 0,
                        name: clinicName,
                        suburb: clinicSuburb,
                        state: clinicState,
                        postcode: clinicPostcode,
                        country: clinicCountry
                    }} />
                </Flex>
                <Box width="calc(40% - 10px)" padding="20px 10px 20px 20px">
                    <Box border="1px solid #CCC">
                        <Box padding="10px" backgroundColor="#285FAA">
                            <Text variant="h3" color="#FFF" fontWeight="bold">Users</Text>
                        </Box>
                        <Box padding="10px">
                            <TableContainer minWidth={100}>
                                <Table variant="simple" size="sm">
                                    <Thead>
                                    <Tr>
                                        <Th>Name</Th>
                                        <Th>Username</Th>
                                        <Th>Email</Th>
                                    </Tr>
                                    </Thead>
                                    <Tbody>
                                    {users.length > 0 &&
                                        users?.map((user) => {
                                            return (
                                                <Tr key={`user_${user.id}`}>
                                                    {/* <Td><Link className={linkStyles.link} to={`/users/${user.id}/view`}>{user.name}</Link></Td>
                                                    <Td><Link className={linkStyles.link} to={`/users/${user.id}/view`}>{user.username}</Link></Td>
                                                    <Td><Link className={linkStyles.link} to={`/users/${user.id}/view`}>{user.email}</Link></Td> */}
                                                    <Td>{user.name}</Td>
                                                    <Td>{user.username}</Td>
                                                    <Td>{user.email}</Td>
                                                </Tr>
                                            );
                                        })}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
                <Box width="calc(60% - 10px)" padding="20px 20px 20px 10px">
                    <Box border="1px solid #CCC">
                        <Box padding="10px" backgroundColor="#285FAA">
                            <Text variant="h3" color="#FFF" fontWeight="bold">Recent Activity</Text>
                        </Box>
                        <Box padding="10px">
                            <TableContainer minWidth={100}>
                                <Table variant="simple" size="sm">
                                    <Thead>
                                    <Tr>
                                        <Th>User</Th>
                                        <Th>Activity</Th>
                                        <Th>Extra Information</Th>
                                        <Th>Time</Th>
                                    </Tr>
                                    </Thead>
                                    <Tbody>
                                    {UserActivity.length > 0 &&
                                        UserActivity?.map((activity, i) => {
                                            let usr = users.find((user) => user.id === activity.userID);
                                            let actDate = new Date(activity.activityTime * 1000);
                                            return (
                                                <Tr key={`activity_${i}`}>
                                                    <Td>{usr?.name}</Td>
                                                    <Td>{activity.activityType}</Td>
                                                    <Td>{activity.activityMeta === "" ? "None" : activity.activityMeta}</Td>
                                                    <Td>
                                                        {actDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false})
                                                            + ", " +
                                                        actDate.toLocaleDateString([], {weekday: "long", year: "numeric", month: "long", day: "2-digit"})}
                                                    </Td>
                                                </Tr>
                                            );
                                        })}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </Page>
    );
}

export default ClinicView;