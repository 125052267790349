import React, { useState } from "react";
import {
  Flex,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  FormControl,
  InputRightElement,
} from "@chakra-ui/react";
import { FaLock } from "react-icons/fa";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Page from "../../components/Page";
import { useLoginState } from "../../hookstate/loginState";
import config from "../../config";
import { useQuery } from "../../utils/functions";
import logo from '../../imgs/logo.png'
import { useGlobalState } from "../../hookstate/globalState";

const CFaLock = chakra(FaLock);

const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    verifyPassword: Yup.string().required("Verify password is required"),
});

const initialValues = {
    password: "",
    verifyPassword: "",
};

const ResetPasswordContainer: React.FC = () => {
  const queryParams = useQuery();
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);

  const loginState = useLoginState();
  const globalState = useGlobalState();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  return (
    <Page title="Optometry Australia Data Collection Portal - Forgot Password">
      <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        backgroundColor="gray.200"
        justifyContent="center"
        alignItems="center"
        color={"black"}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, _actions) => {
            if (!executeRecaptcha) {
              return;
            }

            if (values.password !== values.verifyPassword) {
                globalState.setErrorNotification("Passwords do not match", 5);
                return;
            }

            const result = await executeRecaptcha("UserLogin");

            loginState.callResetPasswordAPI(
                queryParams.get("t") ?? "",
                values.password,
                values.verifyPassword,
                result,
              () => {
                setTimeout(() => {
                    navigate(`/login`);
                }, 3000);
              }
            );
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Stack
                flexDir="column"
                mb="2"
                justifyContent="center"
                alignItems="center"
              >
                <Box>
                  <img src={logo} alt="Optometry Australia Logo" width="400px" />
                </Box>
                <Box minW={{ base: "90%", md: "468px" }}>
                  <Stack
                    spacing={4}
                    p="1rem"
                    backgroundColor="whiteAlpha.900"
                    boxShadow="md"
                  >
                    <FormControl>
                        <InputGroup>
                            <InputLeftElement
                            pointerEvents="none"
                            color="gray.300"
                            children={<CFaLock color="gray.300" />}
                            />
                            <Input
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            />
                            <InputRightElement width="4.5rem">
                            <Button
                                variant={"ghost"}
                                h="1.75rem"
                                size="sm"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                            </Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    <FormControl>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="gray.300"
                          children={<CFaLock color="gray.300" />}
                        />
                        <Input
                          name="verifyPassword"
                          value={values.verifyPassword}
                          onChange={handleChange}
                          type={showVerifyPassword ? "text" : "password"}
                          placeholder="Verify Password"
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            variant={"ghost"}
                            h="1.75rem"
                            size="sm"
                            onClick={() => setShowVerifyPassword(!showVerifyPassword)}
                          >
                            {showVerifyPassword ? <ViewIcon /> : <ViewOffIcon />}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                    <Button
                      borderRadius={5}
                      type="submit"
                      variant="solid"
                      colorScheme="teal"
                      backgroundColor="#285FAA"
                      width="full"
                    >
                      Reset Password
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </form>
          )}
        </Formik>
      </Flex>
    </Page>
  );
};

const ResetPasswordView: React.FC = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
      <ResetPasswordContainer />
    </GoogleReCaptchaProvider>
  );
};

export default ResetPasswordView;
