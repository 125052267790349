import axios from "axios";
import { APIResult } from "./Types";
import config from "../config";
import { parseJwt } from "../helpers";

const APINoToken = (): APIResult => {
  return {
    success: false,
    payload: {
      errorCode: "ERR_NOT_LOGGED_IN",
      errorMesssage: "No token found",
    },
  };
};

const APIExpiredToken = (): APIResult => {
  return {
    success: false,
    payload: {
      errorCode: "ERR_TOKEN_EXPIRED",
      errorMesssage: "Token has expired",
    },
  };
};

const CheckToken = (token: string): APIResult | undefined => {
  if (token === "") {
    return APINoToken();
  }

  const decoded = parseJwt(token);
  if (decoded === undefined || decoded.exp < Date.now() / 1000) {
    return APIExpiredToken();
  }

  return undefined;
};

const NoConnectionError = (): APIResult => {
  return {
    success: false,
    payload: {
      errorCode: "ERR_NO_CONNECTION",
      errorMesssage: "Unable to establish a connection to the API server",
    },
  };
};

const HTTPError = (status: number, statusText: string): APIResult => {
  return {
    success: false,
    payload: {
      errorCode: `ERR_HTTP_${status}`,
      errorMesssage: statusText,
    },
  };
};

export const APIGetRequest = async (
  url: string,
  token: string,
  configData?: {} | undefined,
  params?: {} | undefined
): Promise<APIResult> => {
  const tokenresult = CheckToken(token);
  if (tokenresult !== undefined) {
    return tokenresult;
  }

  let result: APIResult = {
    success: false,
    payload: {
      errorCode: "",
      errorMesssage: "",
    },
  };

  let outResponse: Response;

  await axios
    .get(`${config.apiHost}${url}`, {
      params,
      headers: {
        ...configData,
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (!response.status) {
        throw new Error("HTTPERR");
      }

      return (result = { success: true, payload: response.data });
    })

    .catch((err) => {
      if (err.message === "HTTPERR") {
        result = HTTPError(outResponse.status, outResponse.statusText);
      } else {
        result = NoConnectionError();
      }
    });

  return result;
};

export const APIPostRequest = async (
  url: string,
  token: string,
  params?: URLSearchParams,
  configData?: {} | undefined
): Promise<APIResult> => {
  const tokenresult = CheckToken(token);
  if (tokenresult !== undefined) {
    return tokenresult;
  }

  let result: APIResult = {
    success: false,
    payload: {
      errorCode: "",
      errorMesssage: "",
    },
  };

  await axios
    .post(`${config.apiHost}${url}`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      result = { success: true, payload: response.data };
    });
  return result;
};

export const APIPostRequestJSON = async (
  url: string,
  token: string,
  payload: string,
  configData?: {} | undefined
): Promise<APIResult> => {
  const tokenresult = CheckToken(token);
  if (tokenresult !== undefined) {
    return tokenresult;
  }

  let result: APIResult = {
    success: false,
    payload: {
      errorCode: "",
      errorMesssage: "",
    },
  };

  await axios
    .post(`${config.apiHost}${url}`, payload, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      result = { success: true, payload: response.data };
    });
  return result;
};
