import React, { useState } from "react";
import {
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  FormControl,
  InputRightElement,
  Heading,
} from "@chakra-ui/react";
import { FaLock } from "react-icons/fa";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Page from "../../components/Page";
import { useLoginState } from "../../hookstate/loginState";
import config from "../../config";
import { useGlobalState } from "../../hookstate/globalState";

const CFaLock = chakra(FaLock);

const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Your old password is required"),
    newPassword: Yup.string().required("New password is required"),
    newPasswordConfirm: Yup.string().required("Confirming your new password is required"),
});

const initialValues = {
  oldPassword: "",
  newPassword: "",
  newPasswordConfirm: "",
};

const EditPasswordContainer: React.FC = () => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirm, setShowNewPasswordConfirm] = useState(false);

  const loginState = useLoginState();
  const globalState = useGlobalState();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  return (
    <Page title="Optometry Australia Data Collection Portal - Forgot Password">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, _actions) => {
          if (!executeRecaptcha) {
            return;
          }

          if (values.newPassword !== values.newPasswordConfirm) {
              globalState.setErrorNotification("Passwords do not match", 5);
              return;
          }

          const recaptchaToken = await executeRecaptcha("UserLogin");

          loginState.callEditUserPasswordApi(
            values.oldPassword,
            values.newPassword,
            values.newPasswordConfirm,
            recaptchaToken,
            () => navigate("/dashboard")
          );
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Stack
              flexDir="column"
              mb="2"
              maxWidth="50%"
            >
              <Stack
                spacing={4}
                p="1rem"
                backgroundColor="whiteAlpha.900"
              >
                <Heading
                  fontSize="28px"
                  fontWeight="600"
                  color="#000000"
                >
                  Edit Password
                </Heading>
                <FormControl>
                    <InputGroup>
                        <InputLeftElement
                        pointerEvents="none"
                        color="gray.300"
                        children={<CFaLock color="gray.300" />}
                        />
                        <Input
                          name="oldPassword"
                          value={values.oldPassword}
                          onChange={handleChange}
                          type={showOldPassword ? "text" : "password"}
                          placeholder="Old Password"
                        />
                        <InputRightElement width="4.5rem">
                        <Button
                            variant={"ghost"}
                            h="1.75rem"
                            size="sm"
                            onClick={() => setShowOldPassword(!showOldPassword)}
                        >
                            {showOldPassword ? <ViewIcon /> : <ViewOffIcon />}
                        </Button>
                        </InputRightElement>
                    </InputGroup>
                </FormControl>
                <FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      children={<CFaLock color="gray.300" />}
                    />
                    <Input
                      name="newPassword"
                      value={values.newPassword}
                      onChange={handleChange}
                      type={showNewPassword ? "text" : "password"}
                      placeholder="New Password"
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        variant={"ghost"}
                        h="1.75rem"
                        size="sm"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      >
                        {showNewPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      children={<CFaLock color="gray.300" />}
                    />
                    <Input
                      name="newPasswordConfirm"
                      value={values.newPasswordConfirm}
                      onChange={handleChange}
                      type={showNewPasswordConfirm ? "text" : "password"}
                      placeholder="Verify Password"
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        variant={"ghost"}
                        h="1.75rem"
                        size="sm"
                        onClick={() => setShowNewPasswordConfirm(!showNewPasswordConfirm)}
                      >
                        {showNewPasswordConfirm ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Button
                  borderRadius={5}
                  type="submit"
                  variant="solid"
                  colorScheme="teal"
                  backgroundColor="#285FAA"
                  width="full"
                >
                  Change Password
                </Button>
              </Stack>
            </Stack>
          </form>
        )}
      </Formik>
    </Page>
  );
};

const EditPasswordView: React.FC = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
      <EditPasswordContainer />
    </GoogleReCaptchaProvider>
  );
};

export default EditPasswordView;
