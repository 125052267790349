import React, { useState } from "react";
import {
  Flex,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  FormControl,
  FormHelperText,
  InputRightElement,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Page from "../../components/Page";
import { useLoginState } from "../../hookstate/loginState";
import config from "../../config";
import { useQuery } from "../../utils/functions";
import logo from '../../imgs/logo.png'
import { Link as RouterLink } from "react-router-dom";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

const LoginContainer: React.FC = () => {
  const queryParams = useQuery();

  const [showPassword, setShowPassword] = useState(false);
  const redirectParam = queryParams.get("redirect");

  const loginState = useLoginState();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  return (
    <Page title="Optometry Australia Data Collection Portal - Login">
      <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        backgroundColor="gray.200"
        justifyContent="center"
        alignItems="center"
        color={"black"}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, _actions) => {
            if (!executeRecaptcha) {
              return;
            }

            const result = await executeRecaptcha("UserLogin");

            loginState.callLoginApi(
              values.email,
              values.password,
              result,
              () => {
                redirectParam ? navigate(`/${redirectParam}`) : navigate(`/dashboard`);
              }
            );
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Stack
                flexDir="column"
                mb="2"
                justifyContent="center"
                alignItems="center"
              >
                <Box>
                  <img src={logo} alt="Optometry Australia Logo" width="400px" />
                </Box>
                <Box minW={{ base: "90%", md: "468px" }}>
                  <Stack
                    spacing={4}
                    p="1rem"
                    backgroundColor="whiteAlpha.900"
                    boxShadow="md"
                  >
                    <FormControl>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<CFaUserAlt color="gray.300" />}
                        />
                        <Input
                          name="email"
                          type="text"
                          onChange={handleChange}
                          value={values.email}
                          placeholder="Email Address"
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="gray.300"
                          children={<CFaLock color="gray.300" />}
                        />
                        <Input
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            variant={"ghost"}
                            h="1.75rem"
                            size="sm"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      <FormHelperText textAlign="right">
                        <RouterLink to="/forgot-password">Forgot password?</RouterLink>
                      </FormHelperText>
                    </FormControl>
                    <Button
                      borderRadius={5}
                      type="submit"
                      variant="solid"
                      colorScheme="teal"
                      backgroundColor="#285FAA"
                      width="full"
                    >
                      Login
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </form>
          )}
        </Formik>
      </Flex>
    </Page>
  );
};

const LoginView: React.FC = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
      <LoginContainer />
    </GoogleReCaptchaProvider>
  );
};

export default LoginView;
