import { Persistence } from "@hookstate/persistence";
import { createState, useHookstate } from "@hookstate/core";
import { api } from "./apiState";
import { useGlobalState } from "./globalState";
import { JWTPayload } from "../api/Types";
import { decodedJwt } from "../helpers";

const initialJWTState: JWTPayload = {
  sessionID: "",
  name: "",
  email: "",
  userPermissions: 0,
  exp: 1,
  iat: 1,
  nbf: 1,
};

export interface LoginResponse {
  session: string;
  error: string;
  jwt: JWTPayload;
}

const initialState: LoginResponse = {
  session: "",
  error: "",
  jwt: initialJWTState,
};

const loginState = createState<LoginResponse>(initialState);

export function useLoginState() {
  const state = useHookstate(loginState);
  const globalState = useGlobalState();

  // save data to localstorage
  state.attach(Persistence("login-state"));

  return {
    get session() {
      return state.session.get();
    },
    get error() {
      return state.error.get();
    },
    get jwt() {
      return state.jwt.get();
    },
    callLoginApi: async (
      email: string,
      password: string,
      recaptchaToken: string,
      callback?: () => void
    ) => {
      try {
        const loginResponse = await api.login(email, password, recaptchaToken);
        const { success, data } = loginResponse;
        const jwtSession = decodedJwt(data.payload.session);
        state.set({
          ...state,
          session: data.payload.session,
          error: data.payload.error,
          jwt: {
            sessionID: jwtSession.sessionID,
            name: jwtSession.name,
            email: jwtSession.email,
            userPermissions: jwtSession.userPermissions,
            exp: jwtSession.exp,
            iat: jwtSession.iat,
            nbf: jwtSession.nbf,
          }
        });
        
        if (success) {
          globalState.setSuccessNotification("Successfully logged in", 5);
          callback && callback();
        } else {
          globalState.setErrorNotification("Error logging in", 5);
        }
      } catch (e) {
        globalState.setErrorNotification("Error logging in", 5);
      }
    },

    callForgotPasswordApi: async (
      email: string,
      recaptchaToken: string,
      callback?: () => void
    ) => {
      try {
        const forgotPasswordResponse = await api.forgotPassword(
          email,
          recaptchaToken
        );

        const { success } = forgotPasswordResponse;
        if (success) {
          globalState.setSuccessNotification(
            "Successfully sent forgot password email",
            5
          );
          callback && callback();
        } else {
          globalState.setErrorNotification(
            "Error sending forgot password email",
            5
          );
        }
      } catch (e) {
        globalState.setErrorNotification(
          "Error sending forgot password email",
          5
        );
      }
    },

    callResetPasswordAPI: async (
      t: string,
      password: string,
      verifyPassword: string,
      recaptchaToken: string,
      callback?: () => void
    ) => {
      try {
        const resetPasswordResponse = await api.resetPassword(
          t,
          password,
          verifyPassword,
          recaptchaToken
        );

        const { success } = resetPasswordResponse;
        if (success) {
          globalState.setSuccessNotification(
            "Successfully reset password",
            5
          );
          callback && callback();
        } else {
          if (password !== verifyPassword) {
            globalState.setErrorNotification(
              "Passwords do not match",
              5
            );
          } else {
            globalState.setErrorNotification(
              "Error resetting password",
              5
            );
          }
        }
      } catch (e) {
        globalState.setErrorNotification(
          "Error resetting password",
          5
        );
      }
    },

    callEditUserPasswordApi: async (
      oldPassword: string,
      newPassword: string,
      newPasswordConfirm: string,
      recaptchaToken: string,
      callback?: () => void
    ) => {
      try {
        if (!state.session) {
          globalState.setErrorNotification(
            "Error retrieving token from state",
            5
          );
        } else {
          const editUserPasswordResponse = await api.editUserPassword(
            state.session.value,
            oldPassword,
            newPassword,
            newPasswordConfirm,
            recaptchaToken
          );
            
          const { success } = editUserPasswordResponse;
          if (success) {
            globalState.setSuccessNotification(
              "Successfully edited user password",
              5
            );
            callback && callback();
          } else {
            globalState.setErrorNotification(
              "Error editing user password",
              5
            );
          }
        }
      } catch (e) {
        globalState.setErrorNotification(
          "Error editing user password",
          5
        );
      }
    },

    callLogOut: (callback?: () => void) => {
      if (callback) {
        callback();
      }
      state.set(initialState);
    },
  };
}
