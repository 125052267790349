import React from "react";
import { Navigate } from "react-router-dom";
import ClinicsView from "../views/clinics/ClinicsView";
import { FiHome } from "react-icons/fi";
import { IconType } from "react-icons";
import LoginView from "../views/auth/LoginView";
import NotFoundView from "../views/errors/NotFoundView";
import UsersView from "../views/users/UsersView";
import Dashboard from "../views/dashboard/Dashboard";
import SimpleSidebar from "../layouts/SimpleSideBar";
import { MainLogin } from "../layouts/Main";
import RedirectToLoginWithPath from "../components/RedirectToLoginWithPath";
import ClinicView from "../views/clinics/ClinicView";
import ResetPasswordView from "../views/auth/ResetPasswordView";
import ForgotPasswordView from "../views/auth/ForgotPasswordView";
import EditPasswordView from "../views/auth/EditPasswordView";

interface RouteProps {
  href?: string;
  path: string;
  title: string;
  Icon: IconType;
  Component: React.FC;
  exact?: boolean;
}

export const authed: RouteProps[] = [
  {
    href: "/clinics",
    path: "/clinics",
    Icon: FiHome,
    title: "Optometry Australia Data Collection Portal - Clinics",
    Component: ClinicsView,
  },
  {
    href: "/clinics/:id/view",
    path: "/clinics/:id/view",
    Icon: FiHome,
    title: "Optometry Australia Data Collection Portal - Clinic View",
    Component: ClinicView,
  },
  {
    href: "/users",
    path: "/users",
    Icon: FiHome,
    title: "Optometry Australia Data Collection Portal - Users",
    Component: UsersView,
  },
  {
    href: "/users/:id/view",
    path: "/users/:id/view",
    Icon: FiHome,
    title: "Optometry Australia Data Collection Portal - User View",
    Component: ClinicView,
  },
  {
    href: "/dashboard",
    path: "/dashboard",
    Icon: FiHome,
    title: "Optometry Australia Data Collection Portal - Dashboard",
    Component: Dashboard,
  },
  {
    href: "/edit-password",
    path: "/edit-password",
    Icon: FiHome,
    title: "Optometry Australia Data Collection Portal - Edit Password",
    Component: EditPasswordView,
  },
];

export const clinicAuthed: RouteProps[] = [
  {
    href: "/dashboard",
    path: "/dashboard",
    Icon: FiHome,
    title: "Optometry Australia Data Collection Portal - Dashboard",
    Component: ClinicView,
  },
  {
    href: "/edit-password",
    path: "/edit-password",
    Icon: FiHome,
    title: "Optometry Australia Data Collection Portal - Edit Password",
    Component: EditPasswordView,
  },
]

export const authenticatedRoutes = [
  {
    element: <SimpleSidebar />,
    children: [
      ...authed.map(({ path, Component }) => ({
        path,
        element: <Component />,
      })),
      { path: "", element: <Navigate replace to="/dashboard" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: <SimpleSidebar />,
    children: [
      { path: "", element: <Navigate to="/dashboard" /> },
      { path: "404", element: <NotFoundView /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export const clinicAuthenticatedRoutes = [
  {
    element: <SimpleSidebar />,
    children: [
      ...clinicAuthed.map(({ path, Component }) => ({
        path,
        element: <Component />,
      })),
      { path: "", element: <Navigate replace to="/dashboard" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: <SimpleSidebar />,
    children: [
      { path: "", element: <Navigate to="/dashboard" /> },
      { path: "404", element: <NotFoundView /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export const guest: RouteProps[] = [
  {
    href: "/login",
    Icon: FiHome,
    title: "Login",
    path: "login",
    exact: false,
    Component: LoginView,
  },
  {
    href: "/reset-password",
    Icon: FiHome,
    title: "Reset Password",
    path: "reset-password",
    Component: ResetPasswordView,
  },
  {
    href: "/forgot-password",
    Icon: FiHome,
    title: "Forgot Password",
    path: "forgot-password",
    Component: ForgotPasswordView,
  },
  // {
  //   href: '/register',
  //   Icon: UserPlusIcon,
  //   title: 'Register',
  //   path: 'register',
  //   Component: RegisterView,
  // },
];

export const guestRoutes = [
  {
    element: <MainLogin />,
    children: [
      ...guest.map(({ path, Component }) => {
        return { path, element: <Component /> };
      }),
      { path: "404", element: <NotFoundView /> },
      { path: "/", element: <Navigate to="/login" /> },
      { path: "/reset-password", element: <ResetPasswordView /> },
      { path: "*", element: <RedirectToLoginWithPath /> },
    ],
  },
];
