import { Persistence } from "@hookstate/persistence";
import { createState, useHookstate } from "@hookstate/core";
import { api } from "./apiState";
import { useLoginState } from "../hookstate/loginState";
import { useGlobalState } from "./globalState";
import { Role } from "./roleState";
import { Clinic } from "./clinicState";

export type User = {
  id: number;
  name: string;
  username: string;
  email: string;
  password: string;
  clinic: Clinic;
  role: Role;
};

export type UserRes = {
  offset: number;
  totalResults: number;
  users: User[];
};

const initialUserState: UserRes = {
  offset: 0,
  totalResults: 0,
  users: [],
};

const userState = createState<UserRes>(initialUserState);

export function useUserState() {
  const state = useHookstate(userState);
  const globalState = useGlobalState();

  const loginState = useLoginState();

  // save data to localstorage
  state.attach(Persistence("user-state"));

  return {
    get Users() {
      return state.users.get();
    },

    callGetUsersApi: async (page?: number, limit?: number): Promise<any> => {
      try {
        if (!loginState.session) {
          globalState.setErrorNotification(
            "Error retrieving token from state",
            5
          );
        } else {
          const usersResponse = await api.getUsers(
            loginState.session,
            limit,
            page
          );
          const { success, payload } = usersResponse;

          // array merge or set...
          if (success) {
            state.set({
              ...payload,
            });
            return state.get();
          }
        }
      } catch (e) {
        globalState.setErrorNotification("Error retriving users", 5);
      }
    },

    callCreateUserApi: async (
      name: string,
      username: string,
      email: string,
      password: string,
      clinicID: string,
      roleID: string,
      recaptchaToken: string,
      callback?: () => void
    ): Promise<any> => {
      try {
        if (!loginState.session) {
          globalState.setErrorNotification(
            "Error retrieving token from state",
            5
          );
        } else {
          const createcUserResponse = await api.createUser(
            loginState.session,
            name,
            username,
            email,
            password,
            clinicID,
            roleID,
            recaptchaToken
          );

          const { success, payload } = createcUserResponse;

          // array merge or set...
          if (success) {
            callback && callback();
            globalState.setSuccessNotification("Successfully created user", 5);
            return state.merge(payload);
          }
        }
      } catch (e) {
        globalState.setErrorNotification("Error creating user", 5);
      }
    },

    callEditUserApi: async (
      id: number,
      name: string,
      username: string,
      email: string,
      clinicID: number,
      roleID: string,
      recaptchaToken: string,
      callback?: () => void
    ): Promise<any> => {
      try {
        if (!loginState.session) {
          globalState.setErrorNotification(
            "Error retrieving token from state",
            5
          );
        } else {
          let cID: string = clinicID.toString(10);
          const createcUserResponse = await api.editUser(
            loginState.session,
            id,
            name,
            username,
            email,
            cID,
            roleID,
            recaptchaToken
          );

          const { success, payload } = createcUserResponse;

          // array merge or set...
          if (success) {
            callback && callback();
            globalState.setSuccessNotification("Successfully edited user", 5);
            return state.merge(payload);
          }
        }
      } catch (e) {
        globalState.setErrorNotification("Error editing user", 5);
      }
    },
  };
}
