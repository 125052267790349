import { FC, ChangeEvent, useEffect, useState, MouseEvent } from "react";
import {
  Button,
  Box,
  Center,
  Flex,
  Select,
  Text,
  Stack,
  ChakraProvider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
} from "@chakra-ui/react";
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  ArrowUpDownIcon,
  ArrowUpIcon,
  ArrowDownIcon
} from "@chakra-ui/icons";
import {
  Pagination,
  usePagination,
  PaginationNext,
  PaginationPrevious,
  PaginationContainer,
} from "@ajna/pagination";
import CreateClinics from "./CreateClinics";
import EditClinics from "./EditClinics";
import { useClinicState } from "../../hookstate/clinicState";
import Page from "../../components/Page";
import { checkPermissions, UserPermission } from "../../utils/functions";
import { Link } from "react-router-dom";
import linkStyles from "../../styles/listlink.module.css";
import { useLoginState } from "../../hookstate/loginState";
import { api } from "../../hookstate/apiState";
import sortStyles from "../../styles/sortstyles.module.css";

const ClinicView: FC = () => {
  // states
  const [clinicTotal, setClinicTotal] = useState<number | undefined>(undefined);
  const [clinics, setClinics] = useState<any[]>([]);
  const [sortField, setSortField] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");

  const clinicState = useClinicState();
  const [manuallyHasRequested, setManuallyHasRequested] = useState<Map<number, boolean>>(new Map());
  const loginState = useLoginState();

  // constants
  const outerLimit = 2;
  const innerLimit = 2;
  const pageSizeList = [10, 25, 50, 100];

  const {
    pagesCount,
    offset,
    currentPage,
    setCurrentPage,
    isDisabled,
    pageSize,
    setPageSize,
  } = usePagination({
    total: clinicTotal,
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
    initialState: {
      pageSize: pageSizeList[0],
      isDisabled: false,
      currentPage: 1,
    },
  });

  // effects
  useEffect(() => {
    if(sortField !== "" && sortOrder !== "") {
      clinicState.callGetSortedClinicsAPI(pageSize, currentPage, sortField, sortOrder)
        .then((clinicRes) => {
          setClinicTotal(clinicRes.totalResults);
          setClinics(clinicRes.clinics);
        })
        .catch((error) => {});
    } else {
      clinicState
        .callGetClinicsApi(pageSize, currentPage)
        .then((clinicRes) => {
          setClinicTotal(clinicRes.totalResults);
          setClinics(clinicRes.clinics);
        })
        .catch((error) => {});
    }
  }, [currentPage, pageSize, offset]); // eslint-disable-line react-hooks/exhaustive-deps

  // handlers
  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };

  const handlePageSizeChange = (
    event: ChangeEvent<HTMLSelectElement>
  ): void => {
    const pageSize = Number(event.target.value);

    setPageSize(pageSize);
  };

  const requestExportButtonClick = (event: MouseEvent<HTMLButtonElement>, id: number) => {
    if(loginState.session) {
      api.createExportCommandForClinicID(loginState.session, id);
      setManuallyHasRequested(new Map(manuallyHasRequested.set(id, true)));
    }
  }

  const handleSort = (field: string) => {
    let nextSort = "";

    if (sortField === field) {
      if (sortOrder === "asc") {
        nextSort = "desc";
      } else if (sortOrder === "desc") {
        nextSort = "";
        setSortField("");
      } else {
        nextSort = "asc";
      }
      setSortOrder(nextSort);
    } else {
      nextSort = "asc";
      setSortField(field);
      setSortOrder("asc");
    }

    console.log("sortField: " + field);
    console.log("sortOrder: " + nextSort);

    clinicState.callGetSortedClinicsAPI(pageSize, currentPage, field, nextSort)
    .then((clinicRes) => {
      setClinicTotal(clinicRes.totalResults);
      setClinics(clinicRes.clinics);
    })
    .catch((error) => {});
  };

  return (
    <Page title="Optometry Australia Data Collection Portal - Clinics">
      <ChakraProvider>
        {checkPermissions(
          loginState.jwt.userPermissions,

          UserPermission.CREATE_CLINIC
        ) && <CreateClinics />}

        <Stack padding="10px 20px">
          <TableContainer minWidth={100}>
            <Table variant="simple" size="md">
              <Thead>
                <Tr>
                  <Th>
                    <Flex>
                      <Text flexGrow={1}>Name</Text>
                      <IconButton
                        onClick={() => handleSort("Name")}
                        className={sortStyles.sortButton}
                        as={sortField === "Name"
                          ? sortOrder === "asc"
                            ? ArrowUpIcon
                            : sortOrder === "desc"
                              ? ArrowDownIcon
                              : ArrowUpDownIcon
                          : ArrowUpDownIcon
                        }
                        aria-label="sort column name" />
                    </Flex>
                  </Th>
                  <Th>
                    <Flex>
                      <Text flexGrow={1}>Suburb</Text>
                      <IconButton
                        onClick={() => handleSort("Suburb")}
                        className={sortStyles.sortButton}
                        as={sortField === "Suburb"
                          ? sortOrder === "asc"
                            ? ArrowUpIcon
                            : sortOrder === "desc"
                              ? ArrowDownIcon
                              : ArrowUpDownIcon
                          : ArrowUpDownIcon
                        }
                        aria-label="sort column suburb" />
                    </Flex>
                  </Th>
                  <Th>
                    <Flex>
                      <Text flexGrow={1}>State</Text>
                      <IconButton
                        onClick={() => handleSort("State")}
                        className={sortStyles.sortButton}
                        as={sortField === "State"
                          ? sortOrder === "asc"
                            ? ArrowUpIcon
                            : sortOrder === "desc"
                              ? ArrowDownIcon
                              : ArrowUpDownIcon
                          : ArrowUpDownIcon
                        }
                        aria-label="sort column state" />
                    </Flex>
                  </Th>
                  <Th>
                    <Flex>
                      <Text flexGrow={1}>Country</Text>
                      <IconButton
                        onClick={() => handleSort("Country")}
                        className={sortStyles.sortButton}
                        as={sortField === "Country"
                          ? sortOrder === "asc"
                            ? ArrowUpIcon
                            : sortOrder === "desc"
                              ? ArrowDownIcon
                              : ArrowUpDownIcon
                          : ArrowUpDownIcon
                        }
                        aria-label="sort column country" />
                    </Flex>
                  </Th>
                  <Th width="100px">
                    <Flex>
                      <Text flexGrow={1}>Postcode</Text>
                      <IconButton
                        onClick={() => handleSort("Postcode")}
                        className={sortStyles.sortButton}
                        as={sortField === "Postcode"
                          ? sortOrder === "asc"
                            ? ArrowUpIcon
                            : sortOrder === "desc"
                              ? ArrowDownIcon
                              : ArrowUpDownIcon
                          : ArrowUpDownIcon
                        }
                        aria-label="sort column postcode" />
                    </Flex>
                  </Th>
                  <Th width="150px">
                    <Flex>
                      <Text flexGrow={1}>Total Patients</Text>
                      <IconButton
                        onClick={() => handleSort("Patients")}
                        className={sortStyles.sortButton}
                        as={sortField === "Patients"
                          ? sortOrder === "asc"
                            ? ArrowUpIcon
                            : sortOrder === "desc"
                              ? ArrowDownIcon
                              : ArrowUpDownIcon
                          : ArrowUpDownIcon
                        }
                        aria-label="sort column total patients" />
                    </Flex>
                  </Th>
                  <Th width="150px">
                    <Flex>
                      <Text flexGrow={1}>Total Exams</Text>
                      <IconButton
                        onClick={() => handleSort("Exams")}
                        className={sortStyles.sortButton}
                        as={sortField === "Exams"
                          ? sortOrder === "asc"
                            ? ArrowUpIcon
                            : sortOrder === "desc"
                              ? ArrowDownIcon
                              : ArrowUpDownIcon
                          : ArrowUpDownIcon
                        }
                        aria-label="sort column total exams" />
                    </Flex>
                  </Th>
                  <Th>
                    <Flex>
                      <Text flexGrow={1}>Last Export</Text>
                      <IconButton
                        onClick={() => handleSort("LastExport")}
                        className={sortStyles.sortButton}
                        as={sortField === "LastExport"
                          ? sortOrder === "asc"
                            ? ArrowUpIcon
                            : sortOrder === "desc"
                              ? ArrowDownIcon
                              : ArrowUpDownIcon
                          : ArrowUpDownIcon
                        }
                        aria-label="sort column last export" />
                    </Flex>
                  </Th>
                  <Th width="150px"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {clinics.length > 0 &&
                  clinics?.map((clinic) => {
                    let lastExportDate = new Date(clinic.lastExportTime * 1000);
                    let dateString = lastExportDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false}) + ", " + lastExportDate.toLocaleDateString([], {weekday: "long", year: "numeric", month: "long", day: "2-digit"})
                    return (
                      <Tr key={clinic.id}>
                        <Td>
                          <Link className={linkStyles.link} to={`/clinics/${clinic.id}/view`}>{clinic.name}</Link>
                        </Td>
                        <Td>{clinic.suburb}</Td>
                        <Td>{clinic.state}</Td>
                        <Td>{clinic.country}</Td>
                        <Td>{clinic.postcode}</Td>
                        <Td>{clinic.totalPatients}</Td>
                        <Td>{clinic.totalExams}</Td>

                        <Td>{clinic.lastExportTime === 0 ? "Never" : dateString}</Td>
                        <Td textAlign="center" padding="0px">
                          <Flex columnGap="20px" width="220px" justifyContent="end" alignItems="center">
                            {manuallyHasRequested.has(clinic.id) && manuallyHasRequested.get(clinic.id)
                              ? <Box width="140px" textAlign="center">Export Requested</Box>
                              : clinic.exportPending
                                ? <Box width="140px" textAlign="center">Export Requested</Box>
                                : <Button padding="3px 15px 3px 15px" width="140px" onClick={(event: MouseEvent<HTMLButtonElement>) => requestExportButtonClick(event, clinic.id)}>Request Export</Button>}
                            <EditClinics clinic={clinic} />
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </TableContainer>
          <Center w="full" paddingTop={5}>
            <Select ml={3} onChange={handlePageSizeChange} w={40}>
              {pageSizeList.map((item) => {
                return (
                  <option key={item} value={item}>
                    Show {item}
                  </option>
                );
              })}
            </Select>
          </Center>
          <Pagination
            pagesCount={pagesCount}
            currentPage={currentPage}
            isDisabled={isDisabled}
            onPageChange={handlePageChange}
          >
            <PaginationContainer
              align="center"
              justify="space-between"
              p={4}
              w="full"
            >
              <PaginationPrevious
                  _hover={{
                    bg: "#3C414B",
                  }}
                  bg="#B9BEC3"
                >
                <ArrowLeftIcon />
              </PaginationPrevious>

              {/*  page of total counts */}
              <Text flexShrink="0" mr={8}>
                Page{" "}
                <Text fontWeight="bold" as="span">
                  {currentPage}
                </Text>{" "}
                of{" "}
                <Text fontWeight="bold" as="span">
                  {pagesCount}
                </Text>
              </Text>
              <PaginationNext
                  _hover={{
                    bg: "#3C414B",
                  }}
                  bg="#B9BEC3"
                >
                <ArrowRightIcon />
              </PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Stack>
      </ChakraProvider>
    </Page>
  );
};

export default ClinicView;
