import { FC, useState } from "react";
import {
  Flex,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  FormControl,
} from "@chakra-ui/react";
import { FaUserAlt } from "react-icons/fa";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Page from "../../components/Page";
import { useLoginState } from "../../hookstate/loginState";
import config from "../../config";
import logo from '../../imgs/logo.png'
import { useGlobalState } from "../../hookstate/globalState";

const CFaUserAlt = chakra(FaUserAlt);

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
});

const initialValues = {
  email: "",
};

const ForgotPasswordContainer: FC = () => {
  const loginState = useLoginState();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [ isDisabled, setIsDisabled ] = useState(false);
  const globalState = useGlobalState();

  return (
    <Page title="Optometry Australia Data Collection Portal - Forgot Password">
      <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        backgroundColor="gray.200"
        justifyContent="center"
        alignItems="center"
        color={"black"}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, _actions) => {
            if (!executeRecaptcha) {
              return;
            }

            setIsDisabled(true);

            const result = await executeRecaptcha("UserLogin");

            loginState.callForgotPasswordApi(
              values.email,
              result,
              () => {
                // redirectParam ? navigate(`/${redirectParam}`) : navigate(`/`);
                globalState.setSuccessNotification("Check your email for a reset link", 5);
                setIsDisabled(false);
              }
            );
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Stack
                flexDir="column"
                mb="2"
                justifyContent="center"
                alignItems="center"
              >
                <Box>
                  <img src={logo} alt="Optometry Australia Logo" width="400px" />
                </Box>
                <Box minW={{ base: "90%", md: "468px" }}>
                  <Stack
                    spacing={4}
                    p="1rem"
                    backgroundColor="whiteAlpha.900"
                    boxShadow="md"
                  >
                    <FormControl>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<CFaUserAlt color="gray.300" />}
                        />
                        <Input
                          name="email"
                          type="text"
                          onChange={handleChange}
                          value={values.email}
                          placeholder="Email Address"
                        />
                      </InputGroup>
                    </FormControl>
                    <Button
                      borderRadius={5}
                      type="submit"
                      variant="solid"
                      colorScheme="teal"
                      backgroundColor="#285FAA"
                      width="full"
                      disabled={isDisabled}
                    >
                      Send Reset Email
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </form>
          )}
        </Formik>
      </Flex>
    </Page>
  );
};

const ForgotPasswordView: FC = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
      <ForgotPasswordContainer />
    </GoogleReCaptchaProvider>
  );
};

export default ForgotPasswordView;
